import {
  Middleware,
  EnhancedStore,
  AnyAction,
  Reducer,
} from "@reduxjs/toolkit";
import { IStorageAdapter } from "@kernel-adapters/storage";
import { IFilesystemAdapter } from "@kernel-adapters/filesystem";
import { IConfigAdapter } from "@kernel-adapters/config";
import { ReduxState, EmptyState } from "@kernel-store/reducers";

export enum EAdapterNames {
  Storage = "StorageAdapter",
  Filesystem = "FilesystemAdapter",
  Config = "ConfigAdapter",
}

export type AnyAdapter =
  | IStorageAdapter
  | IFilesystemAdapter
  | IConfigAdapter
  | undefined;

export type TAdapters = {
  [EAdapterNames.Storage]: IStorageAdapter | undefined;
  [EAdapterNames.Filesystem]?: IFilesystemAdapter | undefined;
  [EAdapterNames.Config]: IConfigAdapter | undefined;
};

export type ExtendedState<S> = ReduxState & S;

export type ExtendedStore<S> = EnhancedStore<
  ExtendedState<S> | EmptyState,
  AnyAction,
  Middleware[]
>;

export type PersistStateItem<S> = {
  state: S;
  reducer: Reducer<S>;
  persistStore: (state: S) => Promise<S>;
};

export type StateItems<S> = {
  [key: string]: S[keyof S];
};

export type PersistStateItems<S extends { [key: string]: S[keyof S] }> = {
  store: S;
  reducers: {
    [key in keyof S]: Reducer<S[keyof S]>;
  };
  persistStore: {
    [key in keyof S]: (state: S[keyof S]) => Promise<S[keyof S]>;
  };
};

export type TSession = {
  notificationRequestId: string | number | boolean | null;
  videoRequestId: string | number | boolean | null;
  showInvite: string | number | boolean | null;
};
