import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  initializeVirgil,
  createNewUserVirgil,
  joinGroup,
  acceptUserIntoCompanyVirgilHelper,
  restorePrivateKey,
  isVirgilInitialized,
} from "@tumeke/tumekejs/lib/auth";
import { NotificationManager } from "@kernel-helpers/react-notifications";
import {
  acceptUserIntoCompanyFirebaseHelper as acceptUserIntoCompanyApi,
  getRequestingUsers as getRequestingUsersApi,
  createNewGroupWithUsers as createNewGroupWithUsersApi,
  deleteGroup as deleteGroupApi,
  addUserToGroup as addUserToGroupApi,
  removeUserFromGroup as removeUserFromGroupApi,
  denyUserRequest as denyUserRequestApi,
  getCompanysGroups as getCompanysGroupsApi,
  deleteUsers as deleteUsersApi,
  deleteCompany as deleteCompanyApi,
  updateGroup as updateGroupApi,
  setUserRole as setUserRoleApi,
  sendCompanyNotification as sendCompanyNotificationApi,
  doesEmailExist as doesEmailExistApi,
  inviteUser as inviteUserApi,
} from "@kernel-helpers/DatabaseHelpers";
import type { TAccessGroup } from "@kernel-helpers/DatabaseHelpers";
import { handleVideoError as handleError } from "@kernel-helpers/ErrorHandle";
import { ReduxState } from "@kernel-store/reducers";
import {
  setCompanyData,
  virgilIdGenerator,
  updateUser,
} from "@kernel-store/auth/thunk";
import { decreaseCompanyUserCount } from "@kernel-store/auth/slice";
import { deleteUsers } from "@kernel-store/users/slice";
import { deleteCompany } from "@kernel-store/companies/slice";

type TAccessMetadata = {
  field_id: number;
  values: {
    value: number;
    id: number;
  }[];
};

type TAccessUser = {
  id: number;
  value: number;
};

export const acceptUserIntoCompanyRequest = createAsyncThunk<
  { userId: number },
  { user: any; sendEmail: boolean },
  { state: ReduxState }
>(
  "adminProfile/acceptUserIntoCompanyRequest",
  async ({ user: newUser, sendEmail }, { getState }) => {
    const state = getState();
    const { user, company } = state.authUser;
    if (!user.isSSO && !company.virgil_off) {
      if (!isVirgilInitialized()) {
        console.log("not initialized");
        await initializeVirgil(user.virgil_id);
        await restorePrivateKey("");
        await joinGroup(user, company);
      }

      console.log(`VIRGIL ID: ${newUser.virgil_id}`);
      try {
        await acceptUserIntoCompanyVirgilHelper(newUser.virgil_id);
      } catch (e: any) {
        NotificationManager.warning(
          "Error",
          "Error in verifying ID. Please refresh page and try again.",
          3000,
          null,
          null,
          "",
        );
        throw e;
      }
    }
    try {
      await acceptUserIntoCompanyApi(newUser.id, sendEmail);
    } catch (e: any) {
      handleError(e);
      throw e;
    }

    return { userId: newUser.id };
  },
);

export const initializeRequestListRequest = createAsyncThunk<
  { requestList: any },
  undefined,
  { state: ReduxState }
>("adminProfile/initializeRequestListRequest", async () => {
  let requestList: any = null;
  try {
    requestList = await getRequestingUsersApi();
  } catch (e) {
    console.log("Error initialize request list", e);
    throw e;
  }

  return { requestList };
});

export const initializeGroupsListRequest = createAsyncThunk<
  { userGroups: any },
  undefined,
  { state: ReduxState }
>("adminProfile/initializeGroupsListRequest", async (_, { dispatch }) => {
  let data = null;
  try {
    data = await getCompanysGroupsApi();
  } catch (e) {
    handleError(e);
    throw e;
  }

  console.log(`GROUPS: ${data.groups}`);
  console.log(`COMPANY: ${data.company}`);

  dispatch(setCompanyData({ ...data.company }));
  return { userGroups: data.groups };
});

export const createNewGroupRequest = createAsyncThunk<
  { userGroups: any },
  {
    users: any[];
    groupName: string;
    accessMetadata?: any[];
    accessUsers?: any[];
  },
  { state: ReduxState }
>(
  "adminProfile/createNewGroupRequest",
  async ({ users, groupName, accessMetadata, accessUsers }) => {
    let userGroups: any = null;

    const accessGroups: TAccessGroup[] = [];
    (accessMetadata || []).forEach((metadata: TAccessMetadata) => {
      (metadata.values || []).forEach((value) => {
        accessGroups.push({
          metadata_field_id: metadata.field_id,
          metadata_option_id: value.value,
        });
      });
    });
    (accessUsers || []).forEach((user: TAccessUser) => {
      accessGroups.push({ user_id: user.value });
    });

    try {
      await createNewGroupWithUsersApi(users, groupName, accessGroups);
      userGroups = await getCompanysGroupsApi();
    } catch (e: any) {
      handleError(e);
      throw e;
    }

    return { userGroups: userGroups.groups };
  },
);

export const deleteGroupRequest = createAsyncThunk<
  { groupId: number; moveUsersToGroupId: number },
  { groupId: number; moveUsersToGroupId: number },
  { state: ReduxState }
>(
  "adminProfile/deleteGroupRequest",
  async ({ groupId, moveUsersToGroupId }) => {
    try {
      await deleteGroupApi(groupId, moveUsersToGroupId);
    } catch (e: any) {
      handleError(e);
      throw e;
    }

    return { groupId, moveUsersToGroupId };
  },
);

export const updateGroupRequest = createAsyncThunk<
  { userGroups: any },
  {
    groupId: number;
    groupName: string;
    accessMetadata: any[];
    accessUsers: any[];
  },
  { state: ReduxState }
>(
  "adminProfile/updateGroupRequest",
  async ({ groupId, groupName, accessMetadata, accessUsers }) => {
    let userGroups: any = null;

    const accessGroups: TAccessGroup[] = [];
    (accessMetadata || []).forEach((metadata: TAccessMetadata) => {
      (metadata.values || []).forEach((value) => {
        const newAccessGroup: TAccessGroup = {
          id: undefined,
          metadata_field_id: metadata.field_id,
          metadata_option_id: value.value,
        };
        if (value.id) {
          newAccessGroup.id = value.id;
        }
        accessGroups.push(newAccessGroup);
      });
    });
    (accessUsers || []).forEach((user: TAccessUser) => {
      const newUser: TAccessGroup = {
        id: undefined,
        user_id: user.value,
      };
      if (user.id) {
        newUser.id = user.id;
      }
      accessGroups.push(newUser);
    });

    try {
      await updateGroupApi(groupId, groupName, accessGroups);
      userGroups = await getCompanysGroupsApi();
    } catch (e: any) {
      handleError(e);
      throw e;
    }

    return { userGroups: userGroups.groups };
  },
);

export const moveUserToGroupRequest = createAsyncThunk<
  { userId: number; toGroup: number; fromGroup: number },
  { userId: number; toGroup: number; fromGroup: number },
  { state: ReduxState }
>(
  "adminProfile/moveUserToGroupRequest",
  async ({ userId, toGroup, fromGroup }) => {
    try {
      await addUserToGroupApi(userId, toGroup);
      await removeUserFromGroupApi(userId, fromGroup);
    } catch (e: any) {
      handleError(e);
      throw e;
    }

    return { userId, toGroup, fromGroup };
  },
);

export const addUserToGroupRequest = createAsyncThunk<
  { userGroups: any },
  { user: any; groupId: number },
  { state: ReduxState }
>("adminProfile/addUserToGroupRequest", async ({ user, groupId }) => {
  let userGroups = null;
  try {
    await addUserToGroupApi(user.id, groupId);
    userGroups = await getCompanysGroupsApi();
  } catch (e: any) {
    handleError(e);
    throw e;
  }

  return { userGroups: userGroups.groups };
});

export const acceptUserAndAddToGroup = createAsyncThunk<
  void,
  {
    user: any;
    groupName?: string;
    groupId?: number;
    isNewGroup: boolean;
    sendEmail: boolean;
  },
  { state: ReduxState }
>(
  "adminProfile/acceptUserAndAddToGroup",
  async ({ user, groupName, groupId, isNewGroup, sendEmail }, { dispatch }) => {
    dispatch(acceptUserIntoCompanyRequest({ user, sendEmail }));
    if (isNewGroup) {
      dispatch(createNewGroupRequest({ users: [user], groupName: groupName! }));
    } else {
      dispatch(addUserToGroupRequest({ user, groupId: groupId! }));
    }
  },
);

export const denyUserRequest = createAsyncThunk<
  { userId: number },
  { userId: number },
  { state: ReduxState }
>("adminProfile/denyUserRequest", async ({ userId }, { dispatch }) => {
  try {
    await denyUserRequestApi(userId);
  } catch (e: any) {
    handleError(e);
    throw e;
  }
  dispatch(decreaseCompanyUserCount({ count: 1 }));

  return { userId };
});

export const deleteUsersRequest = createAsyncThunk<
  { fromGroup: number; userIds: number[]; isFull: boolean },
  { fromGroup: number; userIds: number[]; isFull: boolean },
  { state: ReduxState }
>(
  "adminProfile/deleteUsersRequest",
  async ({ fromGroup, userIds, isFull = false }, { dispatch, getState }) => {
    try {
      if (!userIds.length) {
        throw new Error("userIds is empty");
      }
      await deleteUsersApi(userIds, isFull);
      const state = getState();
      if (
        state.users.usersData?.length > 0 &&
        state.users.usersData.some((user: any) => userIds.includes(user.id))
      ) {
        dispatch(decreaseCompanyUserCount({ count: userIds.length }));
        dispatch(deleteUsers({ userIds, isFull }));

        NotificationManager.success(
          "Users deleted successfully",
          "Users deleted",
          3000,
          null,
          null,
          "filled",
        );
      }
    } catch (e: any) {
      handleError(e);
      console.log("Delete user error", e);
      throw e;
    }

    return { fromGroup, userIds, isFull };
  },
);

export const deleteCompanyRequest = createAsyncThunk<
  { companyId: number },
  { companyId: number },
  { state: ReduxState }
>("adminProfile/deleteCompanyRequest", async ({ companyId }, { dispatch }) => {
  try {
    await deleteCompanyApi(companyId);
    dispatch(deleteCompany({ companyId }));
    NotificationManager.success(
      "Company deleted successfully",
      "Company deleted",
      3000,
      null,
      null,
      "filled",
    );
  } catch (e: any) {
    handleError(e);
    console.log("Delete user error", e);
    throw e;
  }

  return { companyId };
});

export const setUserRoleRequest = createAsyncThunk<
  { userGroups: any },
  { userId: number; role: string },
  { state: ReduxState }
>("auth/setUserRoleRequest", async ({ userId, role }) => {
  let userGroups = null;
  try {
    userGroups = await setUserRoleApi(userId, role);
  } catch (e) {
    handleError(e);
    throw e;
  }

  return { userGroups };
});

export const sendCompanyNotificationsRequest = createAsyncThunk<
  { companyId: number },
  { companyId: number; message: string },
  { state: ReduxState }
>("auth/sendCompanyNotificationsRequest", async ({ companyId, message }) => {
  try {
    await sendCompanyNotificationApi(companyId, message);
    NotificationManager.success(
      "Message sent successfully",
      "Message sent",
      3000,
      null,
      null,
      "filled",
    );
  } catch (e: any) {
    NotificationManager.error(
      e.response.data.message,
      "Error",
      3000,
      null,
      null,
      "",
    );
    throw e;
  }

  return { companyId };
});

export const inviteUserRequest = createAsyncThunk<
  { userGroups: any },
  { email: string },
  { state: ReduxState }
>("users/inviteUserRequest", async ({ email }, { dispatch, getState }) => {
  try {
    const state = getState();
    const { authUser } = state;
    const virgilEnabled =
      authUser.company && !authUser.company.virgil_off && !authUser.user.isSSO;
    const canInvite =
      !authUser.user?.has_paid_deal &&
      authUser.user?.trial_active &&
      ((authUser.company &&
        authUser.user &&
        (authUser.company.virgil_off || authUser.user.isSSO)) ||
        (authUser.user &&
          virgilEnabled &&
          authUser.company.owner_id === authUser.user?.id)) &&
      authUser.user?.invites > 0;
    if (!canInvite) {
      throw new Error("You can't invite");
    }
    const result = await doesEmailExistApi(String(email).trim());
    if (result?.message === "Yes") {
      throw new Error("User with this email already exists.");
    }
    if (result?.message !== "No") {
      throw new Error("Invite error");
    }
    const virgilId = virgilEnabled ? virgilIdGenerator() : "";
    const { password } = await inviteUserApi(email, virgilId);
    if (virgilId && password) {
      // Create new virgil user
      await initializeVirgil(virgilId);
      await createNewUserVirgil(password);
      // Restore previous user
      await initializeVirgil(authUser.user.virgil_id);
      await restorePrivateKey("");
      await joinGroup(authUser.user, authUser.company);
      await acceptUserIntoCompanyVirgilHelper(virgilId);
    }
    dispatch(updateUser());
    const userGroups = await getCompanysGroupsApi();
    NotificationManager.success(
      "Invitation has been sent.",
      "Success",
      3000,
      null,
      null,
      "",
    );
    return { userGroups };
  } catch (e: any) {
    console.log("User invitation error", e);
    NotificationManager.warning(
      e?.response?.data?.message || e?.message || "Failed to invite user",
      "Error",
      3000,
      null,
      null,
      "",
    );
    throw e;
  }
});
