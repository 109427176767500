import axios from "axios";
import { asyncGetIdToken } from "../auth/Cognito";
import { IImprovementData } from "./interfaces";

type CallbackFunction = (...args: any[]) => void;

export interface IDatabaseConfig {
  TUMEKE_API: string;
  TUMEKE_SERVER_API: string;
  TUMEKE_ADMIN_PANEL_API: string;
  PLATFORM: string;
  logoutCallback: CallbackFunction | undefined;
}

export const databaseConfig: IDatabaseConfig = {
  TUMEKE_API: "",
  TUMEKE_SERVER_API: "",
  TUMEKE_ADMIN_PANEL_API: "",
  PLATFORM: "web",
  logoutCallback: undefined,
};

const noAuthEndpoints = [
  "doesEmailExist",
  "doesCompanyExist",
  "getUserVirgilId",
  "getVirgilJwt",
  "initiateResetPassword",
  "resetPasswordHelper",
  "confirmResetUserPassword",
  "checkSSO",
  "getFromCode",
  "getAsyncUploadInfo",
  "asyncUploadFinished",
  "externalGetVideoDoc",
  "externalGetVideoManifest",
  "externalGetVideoSlice",
  "externalDownloadVideo",
  "checkUserLimitByName",
  "videos/external",
  "dashboard/external",
  "jobs/external",
  "/virgilId",
  "shortLinks/",
];

// Axios Auth Interceptor
axios.interceptors.request.use(async (config: any): Promise<any> => {
  const { url } = config;
  const arr = url!.split("/");
  // Do not add auth for amazonaws endpoints, and any
  // of the non-auth endpoints on our server (list above)
  const isNonAuthEndpoint =
    arr.length < 4 ||
    arr[2].includes("amazonaws") ||
    noAuthEndpoints.some((x: string) => url.includes(x));

  if (!isNonAuthEndpoint) {
    const idToken = await asyncGetIdToken(
      databaseConfig.PLATFORM,
      databaseConfig.logoutCallback,
    );
    if (idToken === undefined) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw new axios.Cancel(
        "Logging out due to inactivity. Try logging in again",
      );
    }
    // eslint-disable-next-line no-param-reassign
    config.headers.Authorization = idToken;
  }

  // eslint-disable-next-line no-param-reassign
  config.headers["Access-Control-Allow-Origin"] = "*";
  // eslint-disable-next-line no-param-reassign
  // @ts-ignore
  axios.defaults.crossDomain = true;
  return config;
});

export const setAPIEndpoint = (
  api: string,
  serverApi: string,
  adminPanelApi: string,
): void => {
  databaseConfig.TUMEKE_API = api;
  databaseConfig.TUMEKE_SERVER_API = serverApi;
  databaseConfig.TUMEKE_ADMIN_PANEL_API = adminPanelApi;
};

export const setCallback = (
  platform: string,
  callback: CallbackFunction,
): void => {
  databaseConfig.PLATFORM = platform;
  databaseConfig.logoutCallback = callback;
};

export const checkLoginStatus = async (): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/cognito/checkLoginStatus`,
    )
  ).data;

export const submitJointAngleComputeJob = async (
  body: any,
  axiosConfig: any,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/jobs/video/submitJointAngleComputeJob`,
      body,
      axiosConfig,
    )
  ).data;

export const requestFileUpload = async (body: any): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/uploadFile`,
      body,
    )
  ).data;

export const requestAsyncFileUpload = async (body: any): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/uploadFileAsync`,
      body,
    )
  ).data;

export const getAsyncUploadInfo = async (job_id: string): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/jobs/${job_id}/getAsyncUploadInfo`,
    )
  ).data;

export const uploadFinished = async (
  video_id: string,
  uploadData: any,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/${video_id}/uploadFinished`,
      uploadData,
    )
  ).data;

export const asyncUploadFinished = async (
  video_id: string,
  uploadData: any,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/${video_id}/asyncUploadFinished`,
      uploadData,
    )
  ).data;

export const submitNewPosture = async (body: any): Promise<any> =>
  (await axios.post(`${databaseConfig.TUMEKE_SERVER_API}/postures`, body)).data;

export const computeEarthMover = async (
  summaryTable_1: any,
  summaryTable_2: any,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/computeEarthMover`,
      { summaryTable_1, summaryTable_2 },
    )
  ).data;

export const submitNewAssessment = async (
  body: any,
  onUploadProgress: CallbackFunction | null,
): Promise<any> => {
  if (onUploadProgress) {
    return (
      await axios.post(
        `${databaseConfig.TUMEKE_SERVER_API}/assessments`,
        body,
        {
          onUploadProgress,
        },
      )
    ).data;
  }
  return (
    await axios.post(`${databaseConfig.TUMEKE_SERVER_API}/assessments`, body)
  ).data;
};

/// Crud
export interface ICreateCompanyReuqest {
  name: string;
  aes_key: any;
  meta_data: any;
  virgil_id: string;
  consultancy: boolean;
  user_id?: number;
  isAdmin?: boolean;
  limitType?: number;
  assessmentLimit?: number;
  meterStartDate?: string | null;
}
export const createCompany = async ({
  name,
  aes_key,
  meta_data,
  virgil_id,
  consultancy,
  user_id,
  isAdmin = false,
  limitType = 2,
  assessmentLimit = -1,
  meterStartDate = null,
}: ICreateCompanyReuqest): Promise<any> => {
  const request: ICreateCompanyReuqest = {
    name,
    aes_key,
    meta_data,
    virgil_id,
    consultancy,
  };
  if (isAdmin) {
    request.user_id = user_id;
    request.limitType = limitType;
    request.assessmentLimit = assessmentLimit;
    request.meterStartDate = meterStartDate;
  }
  return (
    await axios.post(
      `${
        isAdmin
          ? databaseConfig.TUMEKE_ADMIN_PANEL_API
          : databaseConfig.TUMEKE_SERVER_API
      }/companies/create`,
      request,
    )
  ).data;
};

export interface ICreateUserReuqest {
  name: string;
  email: string;
  password: string;
  virgil_id: string;
  isSendEmail?: boolean;
  emailTemplate?: string;
  companyId?: number;
  isAdmin?: boolean;
}
export const createUser = async ({
  name,
  email,
  password,
  virgil_id,
  companyId,
  isSendEmail = false,
  emailTemplate = "",
  isAdmin = false,
}: ICreateUserReuqest): Promise<any> => {
  const request: ICreateUserReuqest = {
    name,
    email,
    password,
    virgil_id,
  };
  if (isAdmin) {
    request.isSendEmail = isSendEmail;
    request.emailTemplate = emailTemplate;
    request.isAdmin = isAdmin;
    request.companyId = companyId;
  }
  return (
    await axios.post(
      `${
        isAdmin
          ? databaseConfig.TUMEKE_ADMIN_PANEL_API
          : databaseConfig.TUMEKE_SERVER_API
      }/users/create`,
      request,
    )
  ).data;
};

export const inviteUser = async (
  email: string,
  virgilId: string,
): Promise<any> =>
  (
    await axios.post(`${databaseConfig.TUMEKE_SERVER_API}/users/invite`, {
      email,
      virgilId,
    })
  ).data;

export const createGroup = async (
  name: string,
  accessGroups?: {
    id?: number;
    user_id?: number;
    metadata_field_id?: number;
    metadata_option_id?: number;
  }[],
): Promise<any> =>
  (
    await axios.post(`${databaseConfig.TUMEKE_SERVER_API}/groups/create`, {
      name,
      access_groups: accessGroups,
    })
  ).data;

export const updateGroup = async (
  groupId: number,
  groupName: string,
  accessGroups?: {
    id?: number;
    user_id?: number;
    metadata_field_id?: number;
    metadata_option_id?: number;
  }[],
): Promise<any> =>
  (
    await axios.put(
      `${databaseConfig.TUMEKE_SERVER_API}/groups/${groupId}/update`,
      {
        name: groupName,
        access_groups: accessGroups,
      },
    )
  ).data;

export interface IMergeCompaniesRequest {
  company1ID: number;
  company2ID: number;
  newAesKeys: { [key in string]: string };
}

export const mergeCompanies = async ({
  company1ID,
  company2ID,
  newAesKeys,
}: IMergeCompaniesRequest): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_ADMIN_PANEL_API}/companies/merge`,
      {
        company1ID,
        company2ID,
        newAesKeys,
      },
    )
  ).data;

export interface IReassignAdminRequest {
  companyID: number;
  user1ID: number;
  user2ID: number;
}

export const reassignAdmin = async ({
  companyID,
  user1ID,
  user2ID,
}: IReassignAdminRequest): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_ADMIN_PANEL_API}/companies/${companyID}/reassign`,
      {
        user1ID,
        user2ID,
      },
    )
  ).data;

export const addFeedback = async (
  user_id: string,
  text: string,
  type: string,
): Promise<any> =>
  (
    await axios.post(`${databaseConfig.TUMEKE_SERVER_API}/feedback/create`, {
      text,
      type,
    })
  ).data;
/// cRud

// user's own company
export const getCompany = async (): Promise<any> =>
  (await axios.get(`${databaseConfig.TUMEKE_SERVER_API}/companies/my`)).data;

export const getCompanyByName = async (name: string): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/companies/getCompanyByName/${name}`,
    )
  ).data;

export const getCompanyById = async (
  companyId: number,
  isAdmin?: boolean,
): Promise<any> =>
  (
    await axios.get(
      `${
        isAdmin
          ? databaseConfig.TUMEKE_ADMIN_PANEL_API
          : databaseConfig.TUMEKE_SERVER_API
      }/companies/${companyId}`,
    )
  ).data;

export const checkCompanyUserLimit = async (
  companyId?: number,
  companyName?: string,
): Promise<any> => {
  let result: any;
  if (companyId) {
    result = (
      await axios.get(
        `${databaseConfig.TUMEKE_SERVER_API}/companies/checkUserLimitById/${companyId}`,
      )
    ).data;
  } else {
    result = (
      await axios.get(
        `${databaseConfig.TUMEKE_SERVER_API}/companies/checkUserLimitByName/${companyName}`,
      )
    ).data;
  }
  return result;
};

export const getVideoDoc = async (id: string): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/getVideoDoc/${id}`,
    )
  ).data;

export const getVideosByUserId = async (userId: string): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/getVideosByUserId/${userId}`,
    )
  ).data;

export const loginUser = async (): Promise<any> =>
  (await axios.get(`${databaseConfig.TUMEKE_ADMIN_PANEL_API}/users/login`))
    .data;

export const getUserById = async (id: string): Promise<any> =>
  (await axios.get(`${databaseConfig.TUMEKE_SERVER_API}/users/${id}`)).data;

// deprecated? This endpoint doesn't exist even in Tumeke Server
export const getUserByCognitoId = async (cognito_id: string): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_API}/getUserByCognitoId/${cognito_id}`,
    )
  ).data;

export const externalGetVideoDoc = async (short_key: string): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/externalGetVideoDoc/${short_key}`,
    )
  ).data;

export const getGroup = async (group_id: string): Promise<any> =>
  (await axios.get(`${databaseConfig.TUMEKE_SERVER_API}/groups/${group_id}`))
    .data;

export const getAllUserVideos = async (
  filter_object: any,
  assessments_by_field_param: string,
  date_granularity_param: string,
  client_timezone: string,
  page_size: number,
  page_offset: number,
  search: string,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/getAllUserVideos`,
      {
        filter_object,
        assessments_by_field_param,
        date_granularity_param,
        client_timezone,
        page_size,
        page_offset,
        search,
      },
    )
  ).data;

export const getUserVideosById = async (videoIds: string[]): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/userVideosByIds`,
      {
        videos: videoIds,
      },
    )
  ).data;

export const getCompanyVideosByIds = async (videoIds: number[]): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/companyVideosByIds`,
      {
        videoIds,
      },
    )
  ).data;

export const getDeletedVideos = async (videoIds: string[]): Promise<string[]> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/deletedVideos`,
      {
        videos: videoIds,
      },
    )
  ).data;

export const doesEmailExist = async (encoded_email: string): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_ADMIN_PANEL_API}/users/${encoded_email}/doesEmailExist`,
    )
  ).data;

export const checkSSO = async (
  encoded_email: string,
  redirect: string,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/users/${encoded_email}/checkSSO`,
      {
        redirect,
      },
    )
  ).data;

export const getCognitoFromCode = async (
  code: string,
  redirect: string,
  email: string,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/cognito/getFromCode`,
      {
        code,
        redirect,
        email,
      },
    )
  ).data;

export const doesCompanyNameExist = async (
  encoded_company_name: string,
): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/companies/${encoded_company_name}/doesCompanyExist`,
    )
  ).data;

export const doesCompanyIdExist = async (company_id: string): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/companies/${company_id}/doesCompanyIdExist`,
    )
  ).data;

// groups the user belongs to
export const getUsersGroups = async (): Promise<any> =>
  (await axios.get(`${databaseConfig.TUMEKE_SERVER_API}/groups/usersGroups`))
    .data;

// groups the user's company owns
export const getGroupsByCompany = async (): Promise<any> =>
  (await axios.get(`${databaseConfig.TUMEKE_SERVER_API}/groups/byCompany`))
    .data;

export const getRequestingUsers = async (): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/companies/requestingUsers`,
    )
  ).data;

export const getVirgilJwt = async (virgil_id: string): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/virgil/${virgil_id}/getVirgilJwt`,
    )
  ).data;

export const getUserVirgilId = async (encoded_email: string): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/users/${encoded_email}/virgilId`,
    )
  ).data;

export const checkAccountStatus = async (encoded_email: string): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/users/${encoded_email}/checkAccountStatus`,
    )
  ).data;

export const getCompanysGroups = async (): Promise<any> =>
  (await axios.get(`${databaseConfig.TUMEKE_SERVER_API}/groups/byCompany`))
    .data;

export const getProcessedVideo = async (job_id: string): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/jobs/${job_id}/getProcessedVideo`,
    )
  ).data;

export const getJobJointData = async (job_id: string): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/jobs/${job_id}/getJobJointData`,
    )
  ).data;

export const getExternalJobJointData = async (job_id: string): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/jobs/external/${job_id}/getJobJointData`,
    )
  ).data;

export const getJobResult = async (job_id: string): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/jobs/${job_id}/getJobResult`,
    )
  ).data;

export const getClientDepartmentCards = async (
  company_id: string,
  returnType: number,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/companies/${company_id}/getClientDepartmentCards`,
      {
        returnType,
      },
    )
  ).data;

export const getClientDeptObject = async (
  client_dept_option_id: number,
): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/companies/clientDeptObject/${client_dept_option_id}`,
    )
  ).data;

// Dashboard Queries
export const getDashboardFilters = async (): Promise<any> =>
  (await axios.get(`${databaseConfig.TUMEKE_SERVER_API}/dashboard/filters`))
    .data;

export const getDashboardData = async (
  filter_object: any,
  assessments_by_field_param: string,
  dateGranularityParam: string,
  clientTimezone: string,
  aggType: string,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/dashboard/getDashboardData`,
      {
        filter_object,
        assessments_by_field_param,
        date_granularity_param: dateGranularityParam,
        client_timezone: clientTimezone,
        agg_type: aggType,
      },
    )
  ).data;

export const getDashboardDataExternal = async (
  shortKey: string,
): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/dashboard/external/${shortKey}`,
    )
  ).data;

// Compare queries
export const getVideoComparisonData = async (
  video_list: string[],
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/getVideoComparisonData`,
      {
        videos: video_list,
      },
    )
  ).data;

// deprecated? This endpoint doesn't exist even in Tumeke Server
export const joinCompanyRequest = async (id: string): Promise<any> =>
  (await axios.post(`${databaseConfig.TUMEKE_API}/joinCompanyRequest/${id}`))
    .data;

export interface IAddUserToGroup {
  user_id: number;
  group_id: number;
}
export const addUserToGroup = async ({
  user_id,
  group_id,
}: IAddUserToGroup): Promise<any> =>
  axios.post(`${databaseConfig.TUMEKE_SERVER_API}/groups/${group_id}/addUser`, {
    userId: user_id,
  });

export const requestUserCompanyJoin = async (
  companyId: number,
  userId?: number,
  isAdmin?: boolean,
): Promise<any> =>
  axios.post(
    `${
      isAdmin
        ? databaseConfig.TUMEKE_ADMIN_PANEL_API
        : databaseConfig.TUMEKE_SERVER_API
    }/companies/${companyId}/requestUserCompanyJoin${
      isAdmin ? `/${userId}` : ""
    }`,
  );

export const acceptUserCompanyJoin = async (
  userId: number,
  sendEmail: boolean = false,
): Promise<any> =>
  axios.post(
    `${
      databaseConfig.TUMEKE_SERVER_API
    }/companies/acceptUserCompanyJoin/${userId}${
      sendEmail ? "/sendEmail" : ""
    }`,
  );

export const denyUserCompanyJoin = async (user_id: number): Promise<any> =>
  axios.post(
    `${databaseConfig.TUMEKE_SERVER_API}/companies/denyUserCompanyJoin/${user_id}`,
  );

export interface IUpdateAdditionalVideoInfo {
  video_id: string;
  assessment_id: number;
  posture_id: number;
  new_info: any;
  new_metadata: any;
  request_id?: string;
}

export const updateAdditionalVideoInfo = async ({
  video_id,
  assessment_id,
  posture_id,
  new_info,
  new_metadata,
  request_id,
}: IUpdateAdditionalVideoInfo): Promise<any> =>
  axios.post(
    `${databaseConfig.TUMEKE_SERVER_API}/videos/${video_id}/additionalInfo/${assessment_id}/${posture_id}`,
    { new_info, new_metadata, request_id },
  );

export const setVideoMetadata = async (
  video_id: string,
  field_id: number,
  option_id: number,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/metadata/setVideoMetadata`,
      {
        video_id,
        field_id,
        option_id,
      },
    )
  ).data;

// deprecated
export const migrateFirebaseIdtoCognito = async (email: string): Promise<any> =>
  axios.post(`${databaseConfig.TUMEKE_API}/migrateFirebaseIdtoCognito`, {
    email,
  });

export const getThumbnail = async (video_id: string): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/thumbnails/${video_id}`,
    )
  ).data;

export const getPostureThumbnail = async (
  video_id: string,
  frame_num: number,
): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/${video_id}/postureThumbnail/${frame_num}`,
    )
  ).data;

export const getVideoManifest = async (video_id: string): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/${video_id}/manifest`,
    )
  ).data;

export const getVideoJointData = async (
  video_id: string,
  chunk: number,
): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/${video_id}/joints/${chunk}`,
    )
  ).data;

export const getVideoSlice = async (
  video_id: string,
  res: number | string,
  slice: number | string,
): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/${video_id}/slice/${res}/${slice}`,
    )
  ).data;

export const externalGetVideoManifest = async (
  short_key: string,
): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/external/${short_key}/manifest`,
    )
  ).data;

export const externalGetVideoSlice = async (
  short_key: string,
  res: any,
  slice: any,
): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/external/${short_key}/slice/${res}/${slice}`,
    )
  ).data;

export const getAllVideoSlices = async (
  video_id: string,
  res: string,
  source: string,
): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/${video_id}/allSlices/${res}/${source}`,
    )
  ).data;

export const externalDownloadVideoRequest = async (
  videoId: string,
  aesKeys: any,
  email?: string,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/jobs/${videoId}/externalDownloadVideo`,
      { aesKeys, email },
    )
  ).data;

export const generateDownloadableVideo = async (videoId: string) =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/${videoId}/generateDownloadable`,
    )
  ).data;

export const generateViewOnlyLink = async (
  videoId: string,
  aesKeys: any,
  assessmentId: number,
  ttl: number,
) =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/${videoId}/generateLink`,
      { aesKeys, ttl, assessmentId },
    )
  ).data;

export const generateCompareViewOnlyLink = async (
  videoIds: string[],
  aesKeys: any,
  assessmentIds: number[],
  ttl: number,
) =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/generateCompareLink`,
      { aesKeys, ttl, assessmentIds, videoIds },
    )
  ).data;

export const generateDashboardViewOnlyLink = async (
  ttl: number,
  dashboard: any,
) =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/dashboard/generateLink`,
      { ttl, dashboard },
    )
  ).data;

export const generateReport = async (
  videoId: string,
  assessmentId: number,
  subjectId: number,
  postureId?: number,
  reportType?: string,
  reportPages?: string[],
  lang?: string,
  requestId?: string,
): Promise<any> => {
  const uid = "fixme";
  return (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/${videoId}/generateReport/${assessmentId}`,
      { uid, subjectId, reportType, postureId, reportPages, lang, requestId },
    )
  ).data;
};

export const generateGPTRecommendations = async (
  videoId: string,
  assessmentId: number,
  subjectId: number,
  jobDescription: string,
  lang?: string,
) =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/${videoId}/gpt-recommendations/${assessmentId}`,
      { subjectId, jobDescription, lang },
    )
  ).data;

export const externalGenerateGPTRecommendations = async (shortKey: string) =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/external/${shortKey}/gpt-recommendations/`,
    )
  ).data;

export const downloadVideoRequest = async (
  videoId: string,
  aesKeys: any,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/${videoId}/download`,
      {
        aesKeys,
      },
    )
  ).data;

export const getVideosCSVRequest = async (
  filter_object: any,
  assessments_by_field_param: string,
  date_granularity_param: string,
  client_timezone: string,
  page_size: number,
  page_offset: number,
  search: string,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/getVideosCSVRequest`,
      {
        filter_object,
        assessments_by_field_param,
        date_granularity_param,
        client_timezone,
        page_size,
        page_offset,
        search,
      },
    )
  ).data;

export const downloadRiskJointsCsvRequest = async (
  videoId: string,
  assessmentId: number,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/${videoId}/downloadRiskJointsCsvRequest`,
      {
        assessmentId,
      },
    )
  ).data;

// deprecated
export const getAssessmentOverTime = async (
  videoId: string,
  subjectId: number,
): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_API}/getAssessmentOverTime/${videoId}/${subjectId}`,
    )
  ).data;

export interface IUpdateRiskComponentsRequest {
  video_id: string;
  assessment_id: number;
  person_id: number;
  new_info: any;
}

export const updateRiskComponents = async ({
  video_id,
  assessment_id,
  person_id,
  new_info,
}: IUpdateRiskComponentsRequest): Promise<any> =>
  axios.post(
    `${databaseConfig.TUMEKE_SERVER_API}/videos/${video_id}/riskComponents/${assessment_id}/${person_id}`,
    { new_info },
  );

export const addMetadataOption = async (
  field_id: number,
  option_name: string,
  parent_option_id: number,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/metadata/addCompanyMetadataOption`,
      {
        field_id,
        option_name,
        parent_option_id,
      },
    )
  ).data;

export const editMetadataOption = async (
  option_id: number,
  option_name: string,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/metadata/editCompanyMetadataOption`,
      {
        option_id,
        option_name,
      },
    )
  ).data;

export const deleteMetadataOption = async (option_id: number): Promise<any> =>
  (
    await axios.delete(
      `${databaseConfig.TUMEKE_SERVER_API}/metadata/deleteCompanyMetadataOption/${option_id}`,
    )
  ).data;

export const addCompanyMetadataOption = async (
  company_id: number,
  field_id: number,
  option_name: string,
  parent_option_id: number | null,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_ADMIN_PANEL_API}/metadata/${company_id}/addOption`,
      {
        field_id,
        option_name,
        parent_option_id,
      },
    )
  ).data;

export const editCompanyMetadataOption = async (
  company_id: number,
  option_id: number,
  option_name: string,
  parent_option_id: number | null,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_ADMIN_PANEL_API}/metadata/${company_id}/editOption/${option_id}`,
      {
        option_name,
        parent_option_id,
      },
    )
  ).data;

export const deleteCompanyMetadataOption = async (
  company_id: number,
  option_id: number,
): Promise<any> =>
  (
    await axios.delete(
      `${databaseConfig.TUMEKE_ADMIN_PANEL_API}/metadata/${company_id}/deleteOption/${option_id}`,
    )
  ).data;

export const addCompanyMetadataField = async (
  company_id: number,
  field_name: string,
  field_type: string,
  parent_field_id: number | null,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_ADMIN_PANEL_API}/metadata/${company_id}/addField`,
      {
        field_name,
        field_type,
        parent_field_id,
      },
    )
  ).data;

export const editCompanyMetadataField = async (
  company_id: number,
  field_id: number,
  field_name: string,
  field_type: string,
  parent_field_id: number | null,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_ADMIN_PANEL_API}/metadata/${company_id}/editField/${field_id}`,
      {
        field_name,
        field_type,
        parent_field_id,
      },
    )
  ).data;

export const deleteCompanyMetadataField = async (
  company_id: number,
  field_id: number,
): Promise<any> =>
  (
    await axios.delete(
      `${databaseConfig.TUMEKE_ADMIN_PANEL_API}/metadata/${company_id}/deleteField/${field_id}`,
    )
  ).data;

export const updateVideoNotes = async (
  video_id: string,
  assessmentId: number,
  notes: any,
  notesKey: any,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/${video_id}/notes/${assessmentId}`,
      { notes, notesKey },
    )
  ).data;

// deprecated
export const rerunAssessmentFull = async (job_id: string): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_API}/rerunAssessmentFull/${job_id}`,
    )
  ).data;

export const updateVideoName = async (
  video_id: string,
  name: string,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/${video_id}/name`,
      {
        name,
      },
    )
  ).data;

export const updateAssessmentName = async (
  video_id: string,
  assessment_id: number,
  name: string,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/${video_id}/assessmentName/${assessment_id}`,
      { name },
    )
  ).data;

export const initiateResetUserPassword = async (
  encoded_email: string,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/users/${encoded_email}/initiateResetPassword`,
    )
  ).data;

export const resetPasswordHelper = async (
  encoded_email: string,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/users/${encoded_email}/resetPasswordHelper`,
    )
  ).data;

export const confirmResetUserPassword = async (
  encoded_email: string,
  confirmation_code: string,
  new_password: string,
  reset_user: boolean = false,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/users/${encoded_email}/confirmResetUserPassword`,
      { confirmation_code, new_password, reset_user },
    )
  ).data;

export const getUserData = async (
  userId: number,
  withEmails?: boolean,
  withCompany?: boolean,
): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_ADMIN_PANEL_API}/users/${userId}${
        withEmails ? "/withEmails" : ""
      }${withCompany ? "/withCompany" : ""}`,
    )
  ).data;

export const saveUserData = async (
  userId: number,
  data: any,
  isAdmin: boolean,
): Promise<any> =>
  (
    await axios.post(
      `${
        isAdmin
          ? databaseConfig.TUMEKE_ADMIN_PANEL_API
          : databaseConfig.TUMEKE_SERVER_API
      }/users/${userId}`,
      data,
    )
  ).data;

export const setUserRole = async (
  user_id: number,
  role: string,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_ADMIN_PANEL_API}/users/${user_id}/setUserRole`,
      {
        role,
      },
    )
  ).data;

export const deleteUsers = async (
  userIds: number[],
  isFull: boolean = false,
): Promise<any> =>
  (
    await axios.delete(`${databaseConfig.TUMEKE_ADMIN_PANEL_API}/users`, {
      data: {
        userIds,
        isFull,
      },
    })
  ).data;

export const deleteCompany = async (companyId: number): Promise<any> =>
  (
    await axios.delete(
      `${databaseConfig.TUMEKE_ADMIN_PANEL_API}/companies/${companyId}`,
    )
  ).data;

/// cruD

export const deleteNotification = async (notif_id: number): Promise<any> =>
  axios.delete(`${databaseConfig.TUMEKE_SERVER_API}/notifications/${notif_id}`);

export const deleteVideo = async (id: string): Promise<any> =>
  axios.delete(`${databaseConfig.TUMEKE_SERVER_API}/videos/${id}`);

export const deleteVideos = async (ids: string[]): Promise<any> =>
  axios.delete(`${databaseConfig.TUMEKE_SERVER_API}/videos/`, {
    data: {
      ids,
    },
  });

export const deletePosture = async (id: string): Promise<any> =>
  axios.delete(`${databaseConfig.TUMEKE_SERVER_API}/postures/${id}`);

export const deleteAssessment = async (id: number): Promise<any> =>
  axios.delete(`${databaseConfig.TUMEKE_SERVER_API}/assessments/${id}`);

export const setPrimaryAssessment = async (id: number): Promise<any> =>
  axios.post(`${databaseConfig.TUMEKE_SERVER_API}/assessments/${id}/primary`);

export const deleteGroup = async (
  id: number,
  moveUsersToGroupId?: number,
): Promise<any> =>
  axios.delete(`${databaseConfig.TUMEKE_SERVER_API}/groups/${id}`, {
    // temp fix for the backend
    data: { moveUsersToGroupId: String(moveUsersToGroupId) },
  });

export const deleteClip = async (
  videoId: string,
  clipId: number,
): Promise<any> =>
  axios.delete(
    `${databaseConfig.TUMEKE_SERVER_API}/videos/${videoId}/clip/${clipId}`,
  );

export interface IRemoveUserFromGroupRequest {
  user_id: number;
  group_id: number;
}

export const removeUserFromGroup = async ({
  user_id,
  group_id,
}: IRemoveUserFromGroupRequest): Promise<any> =>
  (
    await axios.delete(
      `${databaseConfig.TUMEKE_SERVER_API}/groups/${group_id}/removeUser/${user_id}`,
    )
  ).data;

export const deleteTempPassword = async (user_id: number): Promise<any> =>
  axios.delete(
    `${databaseConfig.TUMEKE_SERVER_API}/users/${user_id}/deleteTempPassword`,
  );

export const getUsersData = async (filter_object: any): Promise<any> =>
  (
    await axios.get(`${databaseConfig.TUMEKE_ADMIN_PANEL_API}/users`, {
      params: filter_object,
    })
  ).data;

export const getCompaniesData = async (filter_object: any): Promise<any> =>
  (
    await axios.get(`${databaseConfig.TUMEKE_ADMIN_PANEL_API}/companies`, {
      params: filter_object,
    })
  ).data;

export const updateCompanyPolicies = async (
  companyId: number,
  body: any,
): Promise<any> =>
  (
    await axios.put(
      `${databaseConfig.TUMEKE_ADMIN_PANEL_API}/companies/${companyId}/policies`,
      body,
    )
  ).data;

export const getTempPasswordsData = async (filter_object: any): Promise<any> =>
  (
    await axios.get(`${databaseConfig.TUMEKE_ADMIN_PANEL_API}/tempPasswords`, {
      params: filter_object,
    })
  ).data;

export const getLinksData = async (): Promise<any> =>
  (await axios.get(`${databaseConfig.TUMEKE_SERVER_API}/videos/getLinks`)).data;

export const updateLink = async (linkId: number, values: any): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/links/${linkId}`,
      { ...values },
    )
  ).data;

export const deleteLink = async (linkId: number): Promise<any> =>
  (
    await axios.delete(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/links/${linkId}`,
    )
  ).data;

export const sendLink = async (
  linkId: number,
  value: string,
  type: string,
): Promise<any> => {
  const params: { phone?: string; email?: string } = {};
  if (type === "phone") {
    params.phone = value;
  } else if (type === "email") {
    params.email = value;
  }
  const result: any = (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/links/${linkId}/send`,
      params,
    )
  ).data;
  return result;
};

export const updateAssessmentROI = async (
  assessmentId: number,
  results: any,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/assessments/${assessmentId}/roi`,
      { ...results },
    )
  ).data;

export const updateCompanySettings = async (
  companyId: number,
  key: string,
  value: string,
): Promise<any> => {
  const params: { key: string; value: string } = { key, value };
  const result: any = (
    await axios.post(
      `${databaseConfig.TUMEKE_ADMIN_PANEL_API}/companies/${companyId}/update`,
      params,
    )
  ).data;
  return result;
};

export const getShortLink = async (shortKey: string): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/shortLinks/${shortKey}`,
    )
  ).data;

export const getTemporaryLink = async (smallKey: string): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/videos/getTemporaryLink/${smallKey}`,
    )
  ).data;

export const saveBaselineTask = async (
  name: string,
  cutoffs: any,
  videoVIds: string[],
  id?: number,
): Promise<any> => {
  const result: any = (
    await axios.post(`${databaseConfig.TUMEKE_SERVER_API}/baselineTasks`, {
      name,
      cutoffs,
      videoVIds,
      id,
    })
  ).data;
  return result;
};

export const deleteBaselineTask = async (id: number): Promise<any> => {
  const result: any = (
    await axios.delete(
      `${databaseConfig.TUMEKE_SERVER_API}/baselineTasks/${id}`,
    )
  ).data;
  return result;
};

export const getConsentUrl = async (
  companyId: number,
): Promise<{ url: string }> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/companies/${companyId}/consent`,
    )
  ).data;

export const registerDevice = async (
  deviceType: string,
  deviceToken: string,
  userTrialActive: boolean,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/devices/registerDevice`,
      {
        device_type: deviceType,
        device_token: deviceToken,
        user_trial_active: userTrialActive,
      },
    )
  ).data;

export const removeDevice = async (
  deviceType: string,
  deviceToken: string,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/devices/removeDevice`,
      {
        device_type: deviceType,
        device_token: deviceToken,
      },
    )
  ).data;

export const getImprovements = async (
  filter: any,
  page_size: number,
  page_offset: number,
): Promise<any> =>
  (
    await axios.get(
      `${
        databaseConfig.TUMEKE_SERVER_API
      }/improvements?limit=${page_size}&offset=${page_offset}${
        filter ? `&filter=${JSON.stringify(filter)}` : ""
      }`,
    )
  ).data;

export const getImprovement = async (improvementId: number): Promise<any> =>
  (
    await axios.get(
      `${databaseConfig.TUMEKE_SERVER_API}/improvements/${improvementId}`,
    )
  ).data;

export const saveImprovement = async (
  improvementData: IImprovementData,
  improvementId?: number,
) =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_SERVER_API}/improvements${
        improvementId ? `/${improvementId}` : ""
      }`,
      improvementData,
    )
  ).data;

export const deleteImprovement = async (improvementId?: number) =>
  (
    await axios.delete(
      `${databaseConfig.TUMEKE_SERVER_API}/improvements/${improvementId}`,
    )
  ).data;

export const sendCompanyNotification = async (
  companyId: number,
  message: string,
): Promise<any> =>
  (
    await axios.post(
      `${databaseConfig.TUMEKE_ADMIN_PANEL_API}/companies/${companyId}/push`,
      { message },
    )
  ).data;
