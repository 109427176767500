import {
  Middleware,
  EnhancedStore,
  AnyAction,
  ReducersMapObject,
} from "@reduxjs/toolkit";
import { EventEmitter2 } from "eventemitter2";
import { initializeUserPools } from "@tumeke/tumekejs";
import { setAPIEndpoint } from "@tumeke/tumekejs/lib/utils/Database";
import { IStorageAdapter } from "@kernel-adapters/storage";
import { updateFromPersist } from "@kernel-store/persist";
import { initializeStore, initializeEmptyStore } from "@kernel-store/store";
import { EmptyState } from "@kernel-store/reducers";
import { IConfigAdapter } from "@kernel-adapters/config";
import { Config as MainConfig } from "@kernel-config";
import {
  AnyAdapter,
  EAdapterNames,
  TAdapters,
  ExtendedState,
  PersistStateItems,
  StateItems,
  TSession,
} from "./types";

class TumekeJSModule<
  S extends StateItems<S>,
  T extends PersistStateItems<S>,
> extends EventEmitter2 {
  adapters: TAdapters = {
    [EAdapterNames.Storage]: undefined,
    [EAdapterNames.Filesystem]: undefined,
    [EAdapterNames.Config]: undefined,
  };

  store: EnhancedStore<ExtendedState<S> | EmptyState, AnyAction, Middleware[]>;

  session: TSession = {
    notificationRequestId: null,
    videoRequestId: null,
    showInvite: null,
  };

  constructor() {
    super();
    this.store = initializeEmptyStore();
  }

  private getAdapter(adapterName: EAdapterNames): AnyAdapter {
    return this.adapters[adapterName];
  }

  public async init(
    adapters: TAdapters,
    extendedStoreWithPersist: T,
  ): Promise<void> {
    this.adapters = adapters;
    const persistStore = await updateFromPersist(
      this.adapters[EAdapterNames.Storage] as IStorageAdapter,
      extendedStoreWithPersist as PersistStateItems<any>,
    );
    const extendedReducers: ReducersMapObject = {};
    if (extendedStoreWithPersist?.reducers) {
      Object.entries(extendedStoreWithPersist.reducers).forEach(
        ([key, reducer]) => {
          extendedReducers[key] = reducer;
        },
      );
    }
    this.store = initializeStore(persistStore, extendedReducers);

    MainConfig.init(this.adapters[EAdapterNames.Config] as IConfigAdapter);

    initializeUserPools(
      MainConfig.COGNITO_POOL as string,
      MainConfig.COGNITO_CLIENT_ID as string,
      MainConfig.COGNITO_EXPIRE_TIME_MINS as number,
    );
    setAPIEndpoint(
      MainConfig.TUMEKE_API as string,
      MainConfig.TUMEKE_SERVER_API as string,
      MainConfig.TUMEKE_ADMIN_PANEL_API as string,
    );

    this.emit("initialized");
  }

  public get(adapterName: EAdapterNames): AnyAdapter {
    return this.getAdapter(adapterName);
  }

  public getStore(): EnhancedStore<
    ExtendedState<S> | EmptyState,
    AnyAction,
    Middleware[]
  > {
    return this.store;
  }

  getSession(key: keyof TSession): string | number | boolean | null {
    return this.session[key];
  }

  setSession(
    key: keyof TSession,
    value: string | number | boolean | null,
  ): void {
    this.session[key] = value;
  }
}

export default new TumekeJSModule();
