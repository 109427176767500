import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  acceptUserIntoCompanyRequest,
  initializeRequestListRequest,
  initializeGroupsListRequest,
  createNewGroupRequest,
  deleteGroupRequest,
  updateGroupRequest,
  moveUserToGroupRequest,
  addUserToGroupRequest,
  denyUserRequest,
  deleteUsersRequest,
  deleteCompanyRequest,
  setUserRoleRequest,
  sendCompanyNotificationsRequest,
  inviteUserRequest,
} from "@kernel-store/adminProfile/thunk";

export type AdminProfileReduxState = {
  usersToAccept: any[];
  loading: boolean;
  error: string;
  userGroups: any[];
};

export const adminProfileInitialState: AdminProfileReduxState = {
  usersToAccept: [],
  loading: false,
  error: "",
  userGroups: [],
};

const adminProfileSlice = createSlice({
  name: "adminProfile",
  initialState: adminProfileInitialState,
  reducers: {
    setAdminProfileLoading: (
      state,
      action: PayloadAction<{ value: boolean }>,
    ) => {
      state.loading = action.payload.value;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(acceptUserIntoCompanyRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(acceptUserIntoCompanyRequest.fulfilled, (state, action) => {
      const userIndex = state.usersToAccept.findIndex(
        (user) => user.id === action.payload.userId,
      );
      if (userIndex > -1) {
        state.usersToAccept.splice(userIndex, 1);
      }
      state.loading = false;
    });
    builder.addCase(acceptUserIntoCompanyRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(initializeRequestListRequest.fulfilled, (state, action) => {
      state.usersToAccept = action.payload.requestList;
    });
    builder.addCase(initializeGroupsListRequest.fulfilled, (state, action) => {
      state.userGroups = action.payload.userGroups;
    });
    builder.addCase(createNewGroupRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createNewGroupRequest.fulfilled, (state, action) => {
      state.userGroups = action.payload.userGroups;
      state.loading = false;
    });
    builder.addCase(createNewGroupRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteGroupRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteGroupRequest.fulfilled, (state, action) => {
      const groupIndex = state.userGroups.findIndex(
        (group) => group.key === action.payload.groupId,
      );
      if (groupIndex > -1) {
        if (action.payload.moveUsersToGroupId) {
          const destinationGroupIndex = state.userGroups.findIndex(
            (group) => group.key === action.payload.moveUsersToGroupId,
          );
          if (destinationGroupIndex > -1) {
            state.userGroups[destinationGroupIndex].userData = [
              ...state.userGroups[destinationGroupIndex].userData,
              ...state.userGroups[groupIndex].userData,
            ];
          }
        }
        state.userGroups.splice(groupIndex, 1);
      }
      state.loading = false;
    });
    builder.addCase(deleteGroupRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(updateGroupRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateGroupRequest.fulfilled, (state, action) => {
      state.userGroups = action.payload.userGroups;
      state.loading = false;
    });
    builder.addCase(updateGroupRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(moveUserToGroupRequest.fulfilled, (state, action) => {
      const fromUserGroup1Index = state.userGroups.findIndex(
        (group) => group.key === action.payload.fromGroup,
      );
      const toUserGroupIndex = state.userGroups.findIndex(
        (group) => group.key === action.payload.toGroup,
      );
      let userDoc: any;
      if (fromUserGroup1Index > -1) {
        const userIndex = state.userGroups[
          fromUserGroup1Index
        ].userData.findIndex((user: any) => user.id === action.payload.userId);
        if (userIndex > -1) {
          userDoc = state.userGroups[fromUserGroup1Index].userData[userIndex];
          state.userGroups[fromUserGroup1Index].userData.splice(userIndex, 1);
        }
      }
      if (userDoc && toUserGroupIndex > -1) {
        const userIndex = state.userGroups[toUserGroupIndex].userData.findIndex(
          (user: any) => user.id === action.payload.userId,
        );
        if (userIndex === -1) {
          state.userGroups[toUserGroupIndex].userData.push(userDoc);
        }
      }
    });
    builder.addCase(addUserToGroupRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addUserToGroupRequest.fulfilled, (state, action) => {
      state.userGroups = action.payload.userGroups;
      state.loading = false;
    });
    builder.addCase(addUserToGroupRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(denyUserRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(denyUserRequest.fulfilled, (state, action) => {
      const userIndex = state.usersToAccept.findIndex(
        (user) => user.id === action.payload.userId,
      );
      if (userIndex > -1) {
        state.usersToAccept.splice(userIndex, 1);
      }
      state.loading = false;
    });
    builder.addCase(denyUserRequest.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteUsersRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteUsersRequest.fulfilled, (state, action) => {
      const groupIndex = state.userGroups.findIndex(
        (group) => group.key === action.payload.fromGroup,
      );
      if (groupIndex > -1) {
        const userIndex = state.userGroups[groupIndex].userData.findIndex(
          (user: any) => action.payload.userIds.includes(user.id),
        );
        if (userIndex > -1) {
          if (action.payload.isFull) {
            state.userGroups[groupIndex].userData.splice(userIndex, 1);
          } else {
            state.userGroups[groupIndex].userData[userIndex].role = "none";
          }
        }
      }
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteUsersRequest.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Error deleting user";
    });
    builder.addCase(deleteCompanyRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteCompanyRequest.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(deleteCompanyRequest.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message || "Error deleting company";
    });
    builder.addCase(setUserRoleRequest.fulfilled, (state, action) => {
      state.userGroups = action.payload.userGroups;
    });
    builder.addCase(sendCompanyNotificationsRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendCompanyNotificationsRequest.fulfilled, (state) => {
      state.loading = false;
      state.error = "";
    });
    builder.addCase(
      sendCompanyNotificationsRequest.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message || "Error sending a message";
      },
    );
    builder.addCase(inviteUserRequest.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(inviteUserRequest.fulfilled, (state, action) => {
      state.userGroups = action.payload.userGroups;
      state.loading = false;
    });
    builder.addCase(inviteUserRequest.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { setAdminProfileLoading } = adminProfileSlice.actions;

export default adminProfileSlice.reducer;
