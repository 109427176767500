import { createAsyncThunk, ThunkDispatch, AnyAction } from "@reduxjs/toolkit";
import moment from "moment-timezone";
import cloneDeep from "lodash/cloneDeep";
import { io } from "socket.io-client";

import * as db from "@tumeke/tumekejs/lib/utils/Database";
import { getCompareObject, asyncGetIdToken } from "@tumeke/tumekejs";
import { TumekeJSModule, EAdapterNames } from "@kernel";
import { IStorageAdapter } from "@kernel-adapters/storage";
import { Config } from "@kernel-config";
import { ProcessingStatus } from "@kernel-constants/maps";
import { NotificationManager } from "@kernel-helpers/react-notifications";
import { Mixpanel } from "@kernel-helpers/Mixpanel";
import {
  getAssessmentFromVideo,
  getNumPeople,
} from "@kernel-helpers/filters/VideoFilters";
import {
  updateAdditionalInfoFirebaseHelper as updateAdditionalInfoApi,
  updateRiskComponentsFirebaseHelper as updateRiskComponentsApi,
  getVideoJsonsLegacy as getVideoJsonsLegacyApi,
  getVideoJsons as getVideoJsonsApi,
  deleteVideosFirebaseHelper as deleteVideosApi,
  addMetadataOptionHelper as addMetadataOptionApi,
  deleteMetadataOptionHelper as deleteMetadataOptionApi,
  editMetadataOptionHelper as editMetadataOptionApi,
  setVideoMetadataFirebaseHelper as setVideoMetadataApi,
  getThumbnail as getThumbnailApi,
  generateReport as generateReportApi,
  getAllUserVideos as getAllUserVideosApi,
  addFeedback as addFeedbackApi,
  getAssessmentOverTime as getAssessmentOverTimeApi,
  getSingleVideoDoc as getSingleVideoDocApi,
  deleteAssessment as deleteAssessmentApi,
  deleteClip as deleteClipApi,
  setPrimaryAssessment as setPrimaryAssessmentApi,
  rerunAssessmentFull as rerunAssessmentFullApi,
  updateVideoNotes as updateVideoNotesApi,
  getPostureThumbnail as getPostureThumbnailApi,
  updateVideoName as updateVideoNameApi,
  updateAssessmentName as updateAssessmentNameApi,
  downloadVideoRequest as downloadVideoRequestApi,
  getJobJointData as getJobJointDataApi,
  getExternalJobJointData as getExternalJobJointDataApi,
  externalGetVideoDoc as externalGetVideoDocApi,
  downloadRiskJointsCsvRequest as downloadRiskJointsCsvRequestApi,
  getLinksData as getLinksDataApi,
  updateLinkHelper as updateLinkApi,
  deleteLinkHelper as deleteLinkApi,
  sendLinkHelper as sendLinkApi,
  externalGenerateGPTRecommendations as externalGenerateGPTRecommendationsApi,
} from "@kernel-helpers/DatabaseHelpers";
import {
  addNewSkinnyVideo,
  addNewVideo,
  setAllInvisible,
  setVideosVisibility,
  setWebsocketFailed,
} from "@kernel-store/videos/slice";
import { processFilterObject } from "@kernel-store/dashboard/thunk";
import { ReduxState } from "@kernel-store/reducers";
import { handleVideoError as handleError } from "@kernel-helpers/ErrorHandle";
import { setDecryptedAESKeysExternal } from "@kernel-store/auth/slice";
import { setCompanyData } from "@kernel-store/auth/thunk";
import { setWebAppLogo } from "@kernel-store/settings/slice";
import { getImprovementThumbnailSuccess } from "@kernel-store/improvements/slice";

const loadingData = {
  videos: false,
};
const allRegisteredListeners: { [key: string]: () => void } = {};

class Poller {
  videoId: string;

  intervalCode: ReturnType<typeof setInterval> | undefined;

  ondata: any;

  constructor(videoId: string) {
    this.videoId = videoId;
  }

  start() {
    this.intervalCode = setInterval(async () => {
      const data = await db.getVideoDoc(this.videoId);
      this.ondata(data);
      return data;
    }, 2000);
  }

  close() {
    clearInterval(this.intervalCode);
  }
}

const getThumbnailName = async (videoId: string) => {
  // todo get thumbnail from server
  const realLoc = await getThumbnailApi(videoId);
  return realLoc.urls;
};

const getPostureThumbnailHelper = async (videoId: string, frame: any) => {
  // todo get thumbnail from server
  const realLoc = await getPostureThumbnailApi(videoId, frame);
  return realLoc.url;
};

export async function mixpanelTrackEvent(
  event: string,
  props?: any,
): Promise<void> {
  await Mixpanel.track(event, props);
}

export const getThumbnailRequest = createAsyncThunk<
  {
    videoId: string;
    realThumbnailLoc: string;
    taskId?: string;
    assessmentId?: string;
    postureId?: string;
  },
  {
    videoId: string;
    taskId?: string;
    assessmentId?: string;
    postureId?: string;
    frameId?: string;
  },
  { state: ReduxState }
>(
  "videos/getThumbnailRequest",
  async (
    { videoId, taskId, assessmentId, postureId, frameId },
    { getState },
  ) => {
    const state = getState();
    const { videoList } = state.videos;

    if (!postureId) {
      // If thumbnail already exists no need to get it again
      if (videoList[videoId].thumbnailLoc.startsWith("https://")) {
        throw new Error("Wrong url");
      }
      let thumbnailRealLoc = null;
      try {
        thumbnailRealLoc = await getThumbnailName(videoId);
      } catch (e) {
        console.log("Can not get posture thumbnail");
      }
      return { videoId, realThumbnailLoc: thumbnailRealLoc[0].url };
    }
    let realThumbnailLoc = "";
    try {
      realThumbnailLoc = await getPostureThumbnailHelper(videoId, frameId);
    } catch (e) {
      console.log("Can not get posture thumbnail");
      throw e;
    }
    return {
      videoId,
      realThumbnailLoc,
      taskId,
      assessmentId,
      postureId,
    };
  },
);

const addNewVideoHelper = (
  { videoObj, initializeAfter }: { videoObj: any; initializeAfter: boolean },
  {
    getState,
    dispatch,
  }: {
    getState: () => ReduxState;
    dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>;
  },
): void => {
  dispatch(addNewVideo({ videoObj, initializeAfter }));

  const state = getState();
  const currentVideoObj = state.videos.videoList[videoObj.key];

  if (videoObj.tasks?.length > 0) {
    // Only pull all thumbnails if postures exists.
    // If postures exists then the user has requested ALL
    // the data wrt a video
    if (
      videoObj?.thumbnailLoc &&
      !currentVideoObj?.thumbnailLoc?.startsWith("https")
    ) {
      dispatch(getThumbnailRequest({ videoId: videoObj.key }));
    }

    // TODO ADD LOGIC FOR POSTURE THUMBNAILS?????
  }
};

const registerNewVideoListener = (
  {
    videoId,
    assessmentId,
    isExternal,
  }: {
    videoId: string;
    assessmentId?: number;
    isExternal?: boolean;
  },
  {
    getState,
    dispatch,
  }: {
    getState: () => ReduxState;
    dispatch: ThunkDispatch<ReduxState, unknown, AnyAction>;
  },
): (() => void) => {
  const handleNewVideo = async (snapshotDoc: any) => {
    console.log(`[Listener] handling new video: ${snapshotDoc.id}`);
    const videoData = {
      ...snapshotDoc.data,
      tasks: snapshotDoc.tasks,
      metadata: snapshotDoc.metadata,
      user_id: snapshotDoc.user_id,
      key: snapshotDoc.id,
      id: snapshotDoc.id,
      visible: true,
    };

    const state = getState();

    let visible = true;
    if (isExternal) {
      const assessmentObjNew = getAssessmentFromVideo(
        { [videoId]: videoData },
        videoId,
        assessmentId || 0,
      );
      const assessmentObjOld = getAssessmentFromVideo(
        state.videos.videoList,
        videoId,
        assessmentId || 0,
      );
      // Trigger update only if the condition is met
      if (assessmentObjOld?.data?.notes !== assessmentObjNew?.data?.notes) {
        addNewVideoHelper(
          {
            videoObj: {
              ...videoData,
              id: videoId,
              visible: true,
              key: videoId,
            },
            initializeAfter: true,
          },
          { dispatch, getState },
        );
        NotificationManager.success(
          "GPT recommendations successfully updated",
          "GPT recommendations updated",
          5000,
          null,
          null,
          "filled",
        );
      }

      return;
    }
    /*
     * Here we are processing videos coming in from websockets.
     * If it's a video that we already have a record of, just carry
     * over the old visibility value. We don't want to change
     * the visibility just because we have a change to the video doc.
     * If it's a new video we don't have a prior record of then
     * set it to be automatically visible.
     */
    if (state.videos.videoList[videoData.key]) {
      // eslint-disable-next-line prefer-destructuring
      visible = state.videos.videoList[videoData.key].visible;
    } else {
      visible = true;
    }
    let jointUrls = {};
    try {
      jointUrls = await getJobJointDataApi(videoId);
    } catch (e) {
      console.log("Joint data error", e);
    }
    addNewVideoHelper(
      {
        videoObj: {
          ...videoData,
          jointUrls,
          visible,
        },
        initializeAfter: true,
      },
      { getState, dispatch },
    );
  };

  const channel = io(`${Config.TUMEKE_SERVER_WEBSOCKET_API}/videos`);

  channel.on("connect", async () => {
    if (isExternal) {
      channel.emit("externalSubscribeToVideoDoc", {
        shortKey: videoId,
      });
    } else {
      const authToken: string = await asyncGetIdToken("web");
      channel.emit("subscribeToVideoDoc", {
        vId: videoId,
        cognitoAuthToken: authToken,
      });
    }
  });
  channel.on("disconnect", () => {
    console.log("Video channel closed");
  });

  const poller = new Poller(videoId);

  poller.ondata = (resp: any) => {
    handleNewVideo(resp);
  };

  let usingPoller = false;

  channel.on("videoDocToClient", (response: any) => {
    if (response?.errorCode === "RECOMPUTE_FAILED" && response?.requestId) {
      const requestId = TumekeJSModule.getSession("videoRequestId") as string;
      if (requestId === response?.requestId) {
        NotificationManager.error(
          "Failed to recompute the video. Try with other parameters",
          "Error",
          5000,
          null,
          null,
          "",
        );
      }
    }
    const processedVideoDoc = {
      ...response.data.data,
      ...response.data,
      key: response.data.id,
    };
    handleNewVideo(processedVideoDoc);
  });

  channel.on("errorToClient", (data: any) => {
    if (data.message === "Bad auth") {
      channel.close();
      return;
    }
    dispatch(setWebsocketFailed({ websocketFailed: true }));
    usingPoller = true;
    poller.start();
  });

  channel.on("error", () => {
    dispatch(setWebsocketFailed({ websocketFailed: true }));
    usingPoller = true;
    poller.start();
  });

  const unsubscribe = () => {
    console.log("Video channel closed");
    delete allRegisteredListeners[videoId];
    if (usingPoller) {
      poller.close();
    } else {
      channel.close();
    }
  };

  return unsubscribe;
};

export const getThumbnailsRequest = createAsyncThunk<
  { videoId: string; url: string }[],
  {
    videoIds: string[];
    pullAssessmentThumbnails?: any;
    isImprovement?: boolean;
  },
  { state: ReduxState }
>(
  "videos/getThumbnailsRequest",
  async ({ videoIds, isImprovement }, { getState, dispatch }) => {
    const state = getState();
    const { videoList } = state.videos;
    // const shouldRequestThumbnails = false;
    // If any of the videoids DON't have a
    // thumbnail already, fire off the request
    const finalList = [];
    for (let i = 0; i < videoIds.length; i += 1) {
      if (
        !(
          videoList[videoIds[i]] &&
          videoList[videoIds[i]].thumbnailLoc.startsWith("https://")
        ) ||
        isImprovement
      ) {
        finalList.push(videoIds[i]);
      }
    }

    console.log("[getThumbnailsRequest]", finalList);
    if (finalList.length === 0) {
      throw new Error("Empty thumbnails");
    }

    // Only send 32 thumbnail requests at a time to
    // avoid overwhelming the URL
    let i = 0;
    const successThumbnails: { videoId: string; url: string }[] = [];
    while (i < finalList.length) {
      const slice = finalList.slice(i, i + 32);
      let videoIdStr = slice[0];
      for (let j = 1; j < slice.length; j += 1) {
        videoIdStr += `,${slice[j]}`;
      }
      let thumbnailRealLocs = null;
      try {
        // eslint-disable-next-line no-await-in-loop
        thumbnailRealLocs = await getThumbnailName(videoIdStr);
      } catch (e) {
        console.log("Error get thumbnail", videoIdStr);
        throw e;
      }
      for (let j = 0; j < thumbnailRealLocs.length; j += 1) {
        if (isImprovement) {
          dispatch(
            getImprovementThumbnailSuccess({
              videoId: thumbnailRealLocs[j].videoId,
              url: thumbnailRealLocs[j].url,
            }),
          );
        } else {
          successThumbnails.push({
            videoId: thumbnailRealLocs[j].videoId,
            url: thumbnailRealLocs[j].url,
          });
        }
      }
      i += 32;
    }
    return successThumbnails;
  },
);

export const addSingleVideoListener = createAsyncThunk<
  void,
  {
    videoId: string;
    listenerType?: string;
    isExternal?: boolean;
    assessmentId?: number;
  },
  { state: ReduxState }
>(
  "videos/addSingleVideoListener",
  async (
    { videoId, listenerType, isExternal, assessmentId },
    { getState, dispatch },
  ) => {
    if (allRegisteredListeners[videoId]) {
      dispatch(setVideosVisibility({ videoIds: [videoId], visible: true }));
      console.log("[Listener] Listener already registered");
      return;
    }
    // If the listener is meant to listen for processing
    // updates to a video (to update video list) then don't
    // install. Only install listeners meant to check for
    // updates once manual/model detected inputs are adjusted
    if (listenerType === "PROCESSING_LIST_UPDATE") {
      return;
    }
    const listener = registerNewVideoListener(
      {
        videoId,
        assessmentId,
        isExternal,
      },
      { dispatch, getState },
    );
    allRegisteredListeners[videoId] = listener;
  },
);

export const removeVideoListeners = createAsyncThunk<
  void,
  { videoId?: string },
  { state: ReduxState }
>("videos/removeVideoListeners", async ({ videoId }) => {
  if (videoId) {
    if (allRegisteredListeners[videoId]) {
      allRegisteredListeners[videoId]();
    }
  } else {
    const allRegisteredListenersKeys = Object.keys(
      allRegisteredListeners,
    ) as string[];
    allRegisteredListenersKeys.forEach((key) => {
      allRegisteredListeners[key]();
    });
  }
});

export const getVideoListRequest = createAsyncThunk<
  {
    videoCount: number;
    totalVideoDurationMins: number;
    videoLimitCount: number;
  },
  {
    userId: string;
    companyId: string;
    groupId: string;
    pageSizeArg?: number;
    pageOffsetArg?: number;
    search?: string;
    history?: { push: (url: string) => void };
  },
  { state: ReduxState }
>(
  "videos/getVideoListRequest",
  async ({ pageSizeArg, pageOffsetArg, search }, { getState, dispatch }) => {
    const state = getState();

    const filterObject = processFilterObject(state.dashboard.filterObject);

    if (loadingData.videos) {
      throw new Error("Already loading");
    }
    dispatch(setAllInvisible());

    let videoList: any[];
    let videoListObj: any;
    let videoCount: number;
    let totalVideoDurationMins: number;
    let videoLimitCount: number;

    let pageSize = pageSizeArg;
    let pageOffset = pageOffsetArg;
    if (pageSize === undefined) {
      // eslint-disable-next-line prefer-destructuring
      pageSize = state.videos.pageMetadata.pageSize;
    }
    if (pageOffset === undefined) {
      pageOffset = (state.videos.pageMetadata.currentPage - 1) * pageSize;
    }

    try {
      videoListObj = await getAllUserVideosApi(
        filterObject,
        "",
        "",
        Intl.DateTimeFormat().resolvedOptions().timeZone || moment.tz.guess(),
        pageSize,
        pageOffset,
        search || "",
      );
      videoList = videoListObj.videos;
      // eslint-disable-next-line prefer-destructuring
      videoCount = videoListObj.videoCount;
      // eslint-disable-next-line prefer-destructuring
      totalVideoDurationMins = videoListObj.totalVideoDurationMins;
      // eslint-disable-next-line prefer-destructuring
      videoLimitCount = videoListObj.videoLimitCount;
    } catch (e) {
      // TODO Handle error here
      loadingData.videos = false;
      throw new Error("Loading error");
    }

    const firstVideos = videoList.map((a: any) => a.id);
    dispatch(getThumbnailsRequest({ videoIds: firstVideos }));

    for (let i = 0; i < videoList.length; i += 1) {
      const videoData = videoList[i];
      (videoList[i].metadata || []).sort(
        (a: any, b: any) => a.option_id < b.option_id,
      );
      if (
        videoData.processingStatus !== ProcessingStatus.COMPLETED &&
        videoData.processingStatus !== ProcessingStatus.ERROR
      ) {
        dispatch(addSingleVideoListener({ videoId: videoList[i].id }));
      } else {
        dispatch(
          addNewSkinnyVideo({
            videoObj: {
              ...videoData,
              metadata: videoList[i].metadata,
              user_id: videoList[i].user_id,
              key: videoList[i].id,
            },
          }),
        );
      }
    }
    dispatch(setVideosVisibility({ videoIds: firstVideos, visible: true }));
    loadingData.videos = false;

    const stogareAdapter = TumekeJSModule.get(
      EAdapterNames.Storage,
    ) as IStorageAdapter;
    stogareAdapter.setItem("videoLimitCount", JSON.stringify(videoLimitCount));

    return { videoCount, totalVideoDurationMins, videoLimitCount };
  },
);

export const getSingleVideoRequest = createAsyncThunk<
  void,
  { videoId: string },
  { state: ReduxState }
>(
  "videos/getSingleVideoRequest",
  async ({ videoId }, { getState, dispatch }) => {
    const state = getState();
    console.log("[getSingleVideo]", videoId);
    let video: any;
    try {
      video = await getSingleVideoDocApi(videoId);
    } catch (e: any) {
      console.log("Get single video error", e);
      throw new Error("Video loading error");
    }

    // TODO: Create a config list of all the params that need
    // to be refreshed.
    if (state.videos.videoList[videoId]) {
      dispatch(
        addNewVideo({
          videoObj: {
            ...state.videos.videoList[videoId],
            assessmentMetadata: video.assessmentMetadata,
            tasks: video.tasks,
            jointMetadata: video.jointMetadata,
            summaryStats: video.summaryStats,
            notes: video.notes,
            videoName: video.videoName,
          },
          initializeAfter: true,
        }),
      );
      return;
    }
    console.log("[getSingleVideo]", {
      ...video,
      visible: true,
    });
    dispatch(
      addNewVideo({
        videoObj: {
          ...video,
          visible: true,
        },
        initializeAfter: true,
      }),
    );
    if (
      video.tasks?.length > 0 &&
      video?.thumbnailLoc &&
      !state.videos.videoList[videoId]?.thumbnailLoc?.startsWith("https")
    ) {
      dispatch(getThumbnailRequest({ videoId: video.key }));
    }
  },
);

/* Deprecated */
export const getJointDataLegacy = createAsyncThunk<
  { videoId: string; data: any; chunk: number },
  { videoId: string; chunk: number },
  { state: ReduxState }
>("videos/getJointDataLegacy", async ({ videoId, chunk }, { getState }) => {
  const state = getState();
  const { uid }: { uid: string } = state.videos.videoList[videoId];
  const { personId }: { personId: number } = state.videos;
  let data = null;
  try {
    data = await getVideoJsonsLegacyApi(
      uid,
      videoId,
      personId,
      state.videos.videoList[videoId].jointMetadata,
      chunk,
    );
  } catch (e) {
    if (
      state.videos.videoList[videoId].videoLoc !== undefined &&
      state.videos.videoList[videoId].videoLoc !== ""
    ) {
      NotificationManager.warning(
        "Warning",
        "Joint data not avail. for this video",
        3000,
        null,
        null,
        "",
      );
    }

    throw e;
  }

  return { videoId, data, chunk };
});

export const getJointData = createAsyncThunk<
  { videoId: string; data: any; chunk: number },
  {
    videoId: string;
    chunk: number;
    assessmentId: number;
    clipId: number;
  },
  { state: ReduxState }
>(
  "videos/getJointData",
  async ({ videoId, chunk, assessmentId, clipId }, { getState }) => {
    const state = getState();
    const { personId }: { personId: number } = state.videos;
    let data = null;
    try {
      let { url } = state.videos.videoList[videoId].jointUrls;
      if (videoId === "example" && window && window.location) {
        url = `${window.location.origin}/assets/example/video.bin`;
      }
      const numPeople =
        getNumPeople(state.videos.videoList, videoId, assessmentId, clipId) ||
        1;
      data = await getVideoJsonsApi(
        url,
        numPeople,
        personId,
        state.videos.videoList[videoId].jointMetadata,
        chunk,
      );
    } catch (e) {
      if (
        state.videos.videoList[videoId].videoLoc !== undefined &&
        state.videos.videoList[videoId].videoLoc !== ""
      ) {
        NotificationManager.warning(
          "Warning",
          "Joint data not avail. for this video",
          3000,
          null,
          null,
          "",
        );
      }

      throw e;
    }

    return { videoId, data, chunk };
  },
);

export const getVideoJointUrls = createAsyncThunk<
  { videoId: string; jointUrls: string[] },
  { videoId: string },
  { state: ReduxState }
>("videos/getVideoJointUrls", async ({ videoId }, { getState }) => {
  const state = getState();
  const { jointUrls } = state.videos.videoList[videoId];
  if (jointUrls) {
    throw new Error("Joint url already exists");
  }
  try {
    const newJointUrls = await getJobJointDataApi(videoId);
    return { videoId, jointUrls: newJointUrls };
  } catch (e) {
    console.log("Joint data error", e);
    throw e;
  }
});

export const updateVideoName = createAsyncThunk<
  { videoId: string; name: string },
  { videoId: string; name: string },
  { state: ReduxState }
>("videos/updateVideoName", async ({ videoId, name }) => {
  try {
    await updateVideoNameApi(videoId, name);
  } catch (e: any) {
    if (e.response && e.response.data) {
      NotificationManager.warning(
        e.response.data.message,
        "Warning",
        5000,
        null,
        null,
        "",
      );
      throw e;
    }
    NotificationManager.error(
      "Unknown error. If issue persists please contact support",
      "Error changing name",
      5000,
      null,
      null,
      "",
    );
    throw e;
  }

  return { videoId, name };
});

export const updateAssessmentName = createAsyncThunk<
  { videoId: string; assessmentId: number; name: string },
  { videoId: string; assessmentId: number; name: string },
  { state: ReduxState }
>("videos/updateAssessmentName", async ({ videoId, assessmentId, name }) => {
  try {
    await updateAssessmentNameApi(videoId, assessmentId, name);
  } catch (e: any) {
    if (e.response && e.response.data) {
      NotificationManager.warning(
        e.response.data.message,
        "Warning",
        5000,
        null,
        null,
        "",
      );
      throw e;
    }
    NotificationManager.error(
      "Unknown error. If issue persists please contact support",
      "Error changing name",
      5000,
      null,
      null,
      "",
    );

    throw e;
  }
  NotificationManager.success(
    "Successfully updated",
    "Assessment name",
    3000,
    null,
    null,
    "filled",
  );

  return { videoId, assessmentId, name };
});

/*
 * uploadRiskComponents
 * ------------------------
 * Dispatch meant to save the updated risk cgetJointDataomponent info in Firebase
 * ------------------------
 * Parameters:
 * 	- videoId: The ID of the video you're updating info for
 * 	- assessmentId: The ID of the Assessment (RULA/REBA/NIOSH) for the video
 *	- personId: The person whose info you're modifying
 */
export const uploadRiskComponents = createAsyncThunk<
  void,
  { videoId: string; assessmentId: number; postureId: number },
  { state: ReduxState }
>(
  "videos/uploadRiskComponents",
  async ({ videoId, assessmentId, postureId }, { getState }) => {
    const state = getState();

    const assessmentObj = getAssessmentFromVideo(
      state.videos.videoList,
      videoId,
      assessmentId,
    );

    let riskComponents;
    for (let i = 0; i < assessmentObj.posture_assessments.length; i += 1) {
      const posture = assessmentObj.posture_assessments[i];
      if (posture.id === postureId) {
        // eslint-disable-next-line prefer-destructuring
        riskComponents = posture.riskAssessment.riskComponents;
      }
    }
    try {
      await updateRiskComponentsApi(
        videoId,
        assessmentId,
        postureId,
        riskComponents,
      );
    } catch (e) {
      handleError(e);
      return;
    }

    mixpanelTrackEvent("RS - Event - Update Model Detected Inputs");
  },
);

export const uploadAdditionalInfo = createAsyncThunk<
  void,
  { videoId: string; assessmentId: number; postureId: number },
  { state: ReduxState }
>(
  "videos/uploadAdditionalInfo",
  async ({ videoId, assessmentId, postureId }, { getState }) => {
    const state = getState();

    const assessmentObj = getAssessmentFromVideo(
      state.videos.videoList,
      videoId,
      assessmentId,
    );
    // const assessmentObj = state.videos.videoList[videoId]['tasks'][0]['assessments'].filter(x => x.id == assessmentId)[0];
    let additionalInputs;
    for (let i = 0; i < assessmentObj.posture_assessments.length; i += 1) {
      const posture = assessmentObj.posture_assessments[i];
      if (posture.id === postureId) {
        additionalInputs = cloneDeep(posture.riskAssessment.additionalInputs);
        delete additionalInputs.shortRest;
      }
    }
    try {
      const requestId = TumekeJSModule.getSession("videoRequestId") as string;
      await updateAdditionalInfoApi(
        videoId,
        assessmentId,
        postureId,
        additionalInputs,
        assessmentObj.data.assessmentMetadata,
        requestId || undefined,
      );
    } catch (e) {
      handleError(e);
      return;
    }

    mixpanelTrackEvent("RS - Event - Update Manual Inputs");
  },
);

export const deleteVideosRequest = createAsyncThunk<
  { videoIds: string[] },
  { userId: number; videoIds: string[] },
  { state: ReduxState }
>(
  "videos/deleteVideosRequest",
  async ({ userId, videoIds }, { getState, dispatch }) => {
    const state = getState();
    const userIds: any[] = [];
    videoIds.forEach((videoId: string) => {
      const video = state.videos.videoList[videoId];

      if (
        video.processingStatus !== ProcessingStatus.COMPLETED &&
        video.processingStatus !== ProcessingStatus.ERROR
      ) {
        NotificationManager.warning(
          "Cannot delete a video that is processing",
          "Delete error",
          5000,
          null,
          null,
          "",
        );
        throw new Error("Processing status error");
      }
      if (video.uid !== userId) {
        NotificationManager.warning(
          "You've selected videos that belong to a different user. You can only delete your own videos",
          "Delete error",
          5000,
          null,
          null,
          "",
        );
        throw new Error("Different user error");
      }
      userIds.push(video.uid);
    });
    try {
      await deleteVideosApi(userIds, videoIds);
    } catch (e) {
      handleError(e);
      throw e;
    }
    dispatch(
      getVideoListRequest({
        userId: state.authUser.user.uid,
        companyId: state.authUser.user.companyId,
        groupId: state.authUser.user.groupId,
      }),
    );
    return { videoIds };
  },
);

export const deleteVideoAssessment = createAsyncThunk<
  { videoId: number; assessmentId: number },
  { videoId: number; assessmentId: number },
  { state: ReduxState }
>("videos/deleteVideoAssessment", async ({ videoId, assessmentId }) => {
  try {
    await deleteAssessmentApi(assessmentId);
    NotificationManager.success(
      "Assessment Deleted",
      "Success",
      3000,
      null,
      null,
      "filled",
    );
  } catch (e) {
    NotificationManager.warning(
      "Error deleting assessment. Please note the last assessment cannot be deleted",
      "Error",
      7500,
      null,
      null,
      "",
    );
    throw e;
  }
  mixpanelTrackEvent("RS - Action - Delete Assessment");

  return { videoId, assessmentId };
});

export const deleteVideoClip = createAsyncThunk<
  { videoId: string; clipId: number },
  { videoId: string; clipId: number },
  { state: ReduxState }
>("videos/deleteVideoClip", async ({ videoId, clipId }) => {
  try {
    await deleteClipApi(videoId, clipId);
    NotificationManager.success(
      "Clip Deleted",
      "Success",
      3000,
      null,
      null,
      "filled",
    );
  } catch (e) {
    NotificationManager.warning(
      "Error deleting clip. Please note the clip with associated assessment can not be deleted",
      "Error",
      7500,
      null,
      null,
      "",
    );
    throw e;
  }
  mixpanelTrackEvent("RS - Action - Delete Clip");

  return { videoId, clipId };
});

export const setPrimaryVideoAssessment = createAsyncThunk<
  { videoId: string; assessmentId: number },
  { videoId: string; assessmentId: number },
  { state: ReduxState }
>("videos/setPrimaryVideoAssessment", async ({ videoId, assessmentId }) => {
  try {
    await setPrimaryAssessmentApi(assessmentId);
    NotificationManager.success(
      "Assessment Updated",
      "Success",
      3000,
      null,
      null,
      "filled",
    );
  } catch (e: any) {
    NotificationManager.warning(
      e?.message || "Error when setting primary assessment",
      "Error",
      7500,
      null,
      null,
      "",
    );
    throw e;
  }
  mixpanelTrackEvent("RS - Action - Set Primary Assessment");

  return { videoId, assessmentId };
});

export const rerunVideoRequest = createAsyncThunk<
  void,
  {
    videoId: string;
    history: { push: (url: string) => void };
  },
  { state: ReduxState }
>("videos/rerunVideoRequest", async ({ videoId, history }) => {
  try {
    await rerunAssessmentFullApi(videoId);
  } catch (e) {
    NotificationManager.warning(
      "Rerun error",
      "Please try again later",
      5000,
      null,
      null,
      "",
    );
    return;
  }
  history.push("/app/videos/videos");
});

export const addMetadataOption = createAsyncThunk<
  void,
  {
    fieldId: number;
    optionName: string;
    parentOptionId: number;
    fieldName: string;
  },
  { state: ReduxState }
>(
  "videos/addMetadataOption",
  async ({ fieldId, optionName, parentOptionId }, { dispatch }) => {
    let result;
    try {
      result = await addMetadataOptionApi(fieldId, optionName, parentOptionId);
    } catch (e) {
      handleError(e);
      return;
    }
    NotificationManager.success(
      `"${optionName}" added as option`,
      "Option added",
      3000,
      null,
      null,
      "filled",
    );
    dispatch(setCompanyData({ metadata: result.metadata }));
    mixpanelTrackEvent("RS - Event - Add Metadata Option");
  },
);

export const deleteMetadataOption = createAsyncThunk<
  void,
  {
    optionId: number;
    optionName: string;
  },
  { state: ReduxState }
>(
  "videos/deleteMetadataOption",
  async ({ optionId, optionName }, { dispatch }) => {
    let result;
    try {
      result = await deleteMetadataOptionApi(optionId);
    } catch (e) {
      handleError(e);
      return;
    }

    NotificationManager.success(
      `"${optionName}" deleted as option`,
      "Option deleted",
      3000,
      null,
      null,
      "filled",
    );

    dispatch(setCompanyData({ metadata: result.metadata }));
    mixpanelTrackEvent("RS - Event - Delete Metadata Option");
  },
);

export const editMetadataOption = createAsyncThunk<
  void,
  {
    optionId: number;
    optionName: string;
    fieldName: string;
  },
  { state: ReduxState }
>(
  "videos/editMetadataOption",
  async ({ optionId, optionName, fieldName }, { dispatch }) => {
    let result;
    try {
      result = await editMetadataOptionApi(optionId, optionName);
    } catch (e) {
      handleError(e);
      return;
    }

    NotificationManager.success(
      `"${optionName}" changed successfully`,
      "Option edit",
      3000,
      null,
      null,
      "filled",
    );

    dispatch(setCompanyData({ metadata: result.metadata }));
    mixpanelTrackEvent("RS - Event - Edit Metadata Option", {
      Field: fieldName,
    });
  },
);

export const setVideoMetadata = createAsyncThunk<
  { videoId: string; metadataObj: any[] },
  {
    videoId: string;
    fieldId: number;
    optionId: number;
    fieldName: string;
  },
  { state: ReduxState }
>(
  "videos/setVideoMetadata",
  async ({ videoId, fieldId, optionId, fieldName }) => {
    try {
      const ret = await setVideoMetadataApi(videoId, fieldId, optionId);
      mixpanelTrackEvent("RS - Event - Set Metadata Option", {
        Field: fieldName,
      });
      return { videoId, metadataObj: ret.metadata };
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
);

export const generateReportRequest = createAsyncThunk<
  { reportType?: string },
  {
    videoId: string;
    assessmentId: number;
    subjectId: number;
    postureId?: number;
    reportType?: string;
    reportPages?: string[];
  },
  { state: ReduxState }
>(
  "videos/generateReportRequest",
  async (
    { videoId, assessmentId, subjectId, postureId, reportType, reportPages },
    { getState },
  ) => {
    const state = getState();
    let response = null;

    try {
      const requestId = TumekeJSModule.getSession(
        "notificationRequestId",
      ) as string;
      response = await generateReportApi(
        videoId,
        assessmentId,
        subjectId,
        postureId,
        reportType,
        reportPages,
        state.settings.locale || "en",
        requestId,
      );
    } catch (e) {
      NotificationManager.warning(
        "Reports only available for new videos",
        "Report generation error",
        5000,
        null,
        null,
        "",
      );
      throw e;
    }
    if (reportType === "worksheet") {
      mixpanelTrackEvent("RS - Event - Generate Worksheet");
    } else {
      mixpanelTrackEvent("RS - Event - Generate Report");
    }
    if (response.url) {
      const link = document.createElement("a");
      link.href = response.url;
      link.setAttribute("download", "report.pdf");
      document.body.appendChild(link);
      link.click();
    }
    return { reportType };
  },
);

export const downloadVideoRequest = createAsyncThunk<
  void,
  {
    videoId: string;
    email?: string;
  },
  { state: ReduxState }
>("videos/downloadVideoRequest", async ({ videoId, email }, { getState }) => {
  const state = getState();
  const { decryptedAESKeys } = state.authUser.user;
  if (!decryptedAESKeys) {
    NotificationManager.warning(
      "Download warning",
      "Still verifying identity... please try again in a few seconds",
      5000,
      null,
      null,
      "",
    );
    throw new Error("Still verifying identity");
  }
  try {
    await downloadVideoRequestApi(
      videoId,
      JSON.stringify(decryptedAESKeys),
      email,
    );
  } catch (e: any) {
    console.log("Download video error", e);
    throw e;
  }
  NotificationManager.primary(
    `We are processing this request. Please check the bell at the top right
      of the page in 2-3 minutes, or your email inbox for a downloadable link.`,
    "Video Download Started",
    15000,
    null,
    null,
    "filled",
  );
  mixpanelTrackEvent("RS - Event - Download Video");
});

export const downloadRiskJointsCsvRequest = createAsyncThunk<
  void,
  {
    videoId: string;
    assessmentId: number;
  },
  { state: ReduxState }
>("videos/downloadRiskJointsCsvRequest", async ({ videoId, assessmentId }) => {
  try {
    await downloadRiskJointsCsvRequestApi(videoId, assessmentId);
  } catch (e) {
    NotificationManager.error(
      "Error in downloading Risk Joints CSV. Please try again, or contact support if this persists",
      "Error",
      5000,
      null,
      null,
      "",
    );
    throw e;
  }
  NotificationManager.primary(
    `We are processing this request. Please check the bell at the top right
        of the page in 2-3 minutes, or your email inbox for a downloadable link.`,
    "Risk Joints CSV Download Started",
    15000,
    null,
    null,
    "filled",
  );
  mixpanelTrackEvent("RS - Action - Download Joints CSV");
});

export const submitVideoFeedback = createAsyncThunk<
  void,
  {
    rating: number;
    feedbackText: string;
  },
  { state: ReduxState }
>(
  "videos/submitVideoFeedback",
  async ({ rating, feedbackText }, { getState }) => {
    const state = getState();
    const { id } = state.authUser.user;
    await addFeedbackApi(id, rating, feedbackText);
  },
);

export const compareVideosRequest = createAsyncThunk<
  { videoIds: string[]; assessmentIds: number[]; orderedData: any },
  { videoIds: string[] },
  { state: ReduxState }
>("videos/compareVideosRequest", async ({ videoIds }, { getState }) => {
  const state = getState();
  const videoDatas: any[] = [];
  const assessmentDatas: any[] = [];
  const assessmentIds: any[] = [];
  const { videoList } = state.videos;
  Object.keys(videoList).forEach((videoKey) => {
    if (videoIds.includes(videoKey)) {
      videoDatas.push(videoList[videoKey]);
      const primaryTask =
        videoList[videoKey].tasks.find((task: any) =>
          task.assessments.some((assessment: any) => assessment.is_primary),
        ) || videoList[videoKey].tasks[0];
      const assessmentObj = cloneDeep(
        primaryTask.assessments.find(
          (assessment: any) => assessment.is_primary,
        ) || primaryTask.assessments[0],
      );
      assessmentIds.push(assessmentObj.id);
      assessmentObj.videoId = videoKey;
      assessmentDatas.push(assessmentObj);
    }
  });
  const data = getCompareObject(assessmentDatas);

  const orderedData: { [key: string]: any[] } = {};
  Object.keys(data).forEach((key) => {
    orderedData[key] = [];
    for (let i = 0; i < data[key].length; i += 1) {
      orderedData[key].push(
        data[key].filter((val: any) => val.videoId === videoIds[i])[0],
      );
    }
  });
  Object.keys(orderedData).forEach((bodyPart) => {
    Object.keys(orderedData[bodyPart]).forEach((key: any) => {
      orderedData[bodyPart][key].videoName =
        videoList[orderedData[bodyPart][key].videoId].videoName;
    });
  });

  return { videoIds, assessmentIds, orderedData };
});

export const saveVideoNotesRequest = createAsyncThunk<
  {
    videoId: string;
    assessmentId: number;
    notes: any[];
    notesKey: string;
  },
  {
    videoId: string;
    assessmentId: number;
    notes: any[];
    notesKey: string;
  },
  { state: ReduxState }
>(
  "videos/saveVideoNotesRequest",
  async ({ videoId, assessmentId, notes, notesKey }) => {
    if (notes.length > 10000) {
      NotificationManager.warning(
        "Keep notes under 10000 characters",
        "Note save error",
        5000,
        null,
        null,
        "",
      );
      throw new Error("Note save error");
    }
    try {
      await updateVideoNotesApi(videoId, assessmentId, notes, notesKey);
    } catch (e) {
      handleError(e);
      throw e;
    }

    return { videoId, assessmentId, notes, notesKey };
  },
);

export const getLinks = createAsyncThunk<
  { linksList: any[] },
  undefined,
  { state: ReduxState }
>("videos/getLinks", async () => {
  let linksList = [];
  try {
    const linksObj = await getLinksDataApi();
    linksList = linksObj?.items || [];

    return { linksList };
  } catch (e: any) {
    handleError(e);
    throw e;
  }
});

export const updateLink = createAsyncThunk<
  { linkId: number; link: any },
  { linkId: number; values: any },
  { state: ReduxState }
>("videos/updateLink", async ({ linkId, values }) => {
  try {
    const linkObj = await updateLinkApi(linkId, values);
    return { linkId, link: linkObj };
  } catch (e: any) {
    handleError(e);
    throw e;
  }
});

export const deleteLink = createAsyncThunk<
  { linkId: number },
  { linkId: number },
  { state: ReduxState }
>("videos/deleteLink", async ({ linkId }) => {
  try {
    await deleteLinkApi(linkId);
    return { linkId };
  } catch (e: any) {
    handleError(e);
    throw e;
  }
});

export const sendLink = createAsyncThunk<
  void,
  { linkId: number; value: string; type: string },
  { state: ReduxState }
>("videos/sendLink", async ({ linkId, value, type }) => {
  try {
    await sendLinkApi(linkId, value, type);
    NotificationManager.success(
      "Message sent successfully",
      "Message sent",
      3000,
      null,
      null,
      "",
    );
  } catch (e: any) {
    handleError(e);
  }
});

export const getVideoDocExternal = createAsyncThunk<
  void,
  {
    videoId: string;
    callback: () => void;
  },
  { state: ReduxState }
>("videos/getVideoDocExternal", async ({ videoId, callback }, { dispatch }) => {
  let videoListObj: any;
  try {
    videoListObj = await externalGetVideoDocApi(videoId);
    const snapshotDoc = videoListObj.video;
    let jointUrls;
    try {
      jointUrls = await getExternalJobJointDataApi(videoId);
      console.log("JOB JOINT URLS", jointUrls);
    } catch (e) {
      console.log("Joint data error", e);
    }
    dispatch(
      addNewVideo({
        videoObj: {
          ...snapshotDoc.data,
          tasks: snapshotDoc.tasks,
          metadata: snapshotDoc.metadata,
          user_id: snapshotDoc.user_id,
          logo: snapshotDoc.logo,
          id: videoId,
          visible: true,
          key: videoId,
          jointUrls,
        },
        initializeAfter: true,
      }),
    );
    dispatch(
      setDecryptedAESKeysExternal({ aesKeys: videoListObj.aes, callback }),
    );
    dispatch(setWebAppLogo({ logo: snapshotDoc.logo }));
  } catch (e) {
    dispatch(
      addNewVideo({
        videoObj: {
          id: videoId,
          key: videoId,
          errorMsg: "Some error",
          processingStatus: 4,
        },
        initializeAfter: false,
      }),
    );
  }
});

export const externalGenerateGPTRecommendationsRequest = createAsyncThunk<
  void,
  {
    videoId: string;
    assessmentId: number;
  },
  { state: ReduxState }
>(
  "videos/externalGenerateGPTRecommendationsRequest",
  async ({ videoId, assessmentId }, { dispatch }) => {
    try {
      await externalGenerateGPTRecommendationsApi(videoId);
    } catch (e) {
      NotificationManager.warning(
        "Recommendation generation error",
        "Generate GPT Request Error",
        5000,
        null,
        null,
        "",
      );
      throw e;
    }

    dispatch(addSingleVideoListener({ videoId, assessmentId }));

    NotificationManager.success(
      `We are processing this request. Please wait 1-3 minutes`,
      "Generate GPT Request Success",
      3000,
      null,
      null,
      "filled",
    );
  },
);

/* Deprecated */
export const generateCSVRequest = createAsyncThunk<
  boolean,
  {
    videoId: string;
    subjectId: number;
  },
  { state: ReduxState }
>("videos/generateCSVRequest", async ({ videoId, subjectId }) => {
  try {
    await getAssessmentOverTimeApi(videoId, subjectId);
  } catch (e) {
    NotificationManager.warning(
      "CSV not available for this video",
      "CSV generation error",
      5000,
      null,
      null,
      "",
    );
    throw e;
  }
  NotificationManager.success(
    `We are processing this request. Please check your inbox in 2-3 minutes with a link to the file`,
    "CSV Request Success",
    3000,
    null,
    null,
    "filled",
  );
  return true;
});

/* Deprecated */
export const getAllMetadataFields = createAsyncThunk<
  { data: any },
  {
    videoId: string;
  },
  { state: ReduxState }
>("videos/getAllMetadataFields", async () => {
  const data = {};
  try {
    // const state: ReduxState = yield select();
    // const companyObj = state.authUser.company;
    // data = yield call(getAllMetadataFieldsFirebaseHelper, companyObj['meta_data'], videoId);
  } catch (err) {
    console.log(`Metadata Error: ${err}`);
  }
  return { data };
});
